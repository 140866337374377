import React, { Component } from "react";
// import "./bottommenu.css";

import { submitDetails } from "../../functions/firebase";
import logoimg from "../../image/being/logo.png";
import descriptionicon from "../../image/being/description.png";
import producticon from "../../image/being/product.png";
import selectcategoryicon from "../../image/being/select category.png";
export class PrefrenceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callback: false,
      product: "",
      category: "",
      email: "",
      description: "",
      // isPrefrenceSubmitted:fale
    };
  }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleClicked = (event) => {
    console.log(event.target.checked);
    this.setState({
      callback: !this.state.callback
    });
    document.getElementById("Opacity").classList.toggle("opacityszero");
  };
  submitData = () => {
    if (this.state.category === "") {
      alert("Please select your category");
    } else {
      submitDetails(
        localStorage.getItem('email'),
        this.state.callback,
        this.state.product,
        this.state.category,
        this.state.description,
        // this.state.isPrefrenceSubmitted

      ).then(res => {
        this.props.setPreferenceFromDb1()
      })
      // this.props.isLogge.d();
    }
  };
  componentDidMount = () => {
    this.setState({
      email: localStorage.getItem("email"),
    });
  };
  render() {
    return (
      <div>
        <div className="layerform" style={{ display: 'block' }}>
        </div>
        <div className="logoposition">
          <img src={logoimg} alt="logo" className="widthbeinglogo" />
        </div>
        <div className="loginformposition toptobottom">

          <div className="formlogin">
            <div className="headerlogin">
              <h3 className="logintitle">Preference</h3>
            </div>
            <div className="formcontainer">
              <div id="input_container" className="marginbottmflex">
                <input
                  id="product"
                  className="input-field"
                  autocomplete="off"
                  type="text"
                  placeholder="Product looking for"
                  name="product"
                  value={this.state.product}
                  onChange={this.handleChange}
                  required
                />
                <img src={producticon} id="input_img" />
              </div>
              <div id="input_container" className="marginbottmflex">
                <div className="countrybox">
                  <select
                    id="select-category"
                    name="category"
                    onChange={this.handleChange}
                    value={this.state.category}
                    className="marignlefttwo"
                  >
                    <option value="">Select Category</option>
                    <option value="exporter">Exporter</option>
                    <option value="agent">Agent</option>
                    <option value="trader">Trader</option>
                  </select>
                </div>
                <img src={selectcategoryicon} id="input_img" />

              </div>
              {/* {this.state.callback ? ( */}
              <div id="Opacity" className="opacityszero" style={{ width: '100%' }} >
                <div id="input_container" className="marginbottmflex">
                  <input
                    id="description"
                    className="input-field"
                    autocomplete="off"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                  <img src={descriptionicon} id="input_img" />

                </div>

              </div>
              {/* ) : (
                  ""
                )} */}
              <div className="flexinput margintopcheckbox" id="remove">
                <input
                  type="checkbox"
                  name="callback"
                  value={this.state.callback}
                  onChange={this.handleClicked}
                  className="marginrightcheckbox"
                />
                <p for="callback" className="producttext">
                  Should we call back for product you are interested in
                  </p>
              </div>

              <button
                className="logintbn marginbottmflex"
                onClick={this.submitData}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrefrenceForm;
