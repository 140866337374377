import React from 'react';
import ReactDOM from 'react-dom';
// import RouterContainer from "./router-container";
// import { Router } from "react-router-dom";
// import { createBrowserHistory } from "history";
// import Bottommenu from "./components/bottommenu/bottommenu";
// import Footer from "./components/footer/footer";
import App from './App';
import reportWebVitals from './reportWebVitals';
// const history = createBrowserHistory();
// 


ReactDOM.render(
  <React.StrictMode>
    {/* <Router history={history}>
      <RouterContainer />
    </Router>
    <Bottommenu />
    <Footer /> */}
    <App/>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
