import React, { Component } from 'react';
import { checkForVerificationToken } from '../../functions/firebase';
import logoimg from "../../image/being/logo.png";
import BackGround from '../background/background';
import Footer from '../footer/footer';

export default class Verify extends Component {

    componentDidMount() {

        if (window.location.hash.length > 0) {
            let hashValue = window.location.hash.replace('#', '');
            console.log('Token is', hashValue);
            checkForVerificationToken(hashValue);
        } else {
            document.getElementById('verifyInfoPopup').style.display = 'block';
        }
    }


    render() {
        return (
            <div >
                <BackGround iframePath="https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/0/novr/none" />
                {/* <div className="overlay">
                </div>/ */}
               <div  className="layerform">

                <div className="logoposition">
                    <img src={logoimg} alt="logo" className="widthbeinglogo" />
                </div>
                <div className="loginformposition toptobottom" style={{ height: '330px' }}>

                    <div className="formlogin" id="verifyInfoPopup" style={{display: 'none'}}>
                        <div className="headerlogin headerlogin1">
                            <h4 className="logintitle">Account Verification</h4>
                            {/* <h3 className="logintitle">Account Verified</h3> */}

                        </div>
                        <div className="formcontainer  formcontainer1">
                            <p className="sizeverifytext">
                                We have sent you an activation link to your registered email address.<br></br><br></br> Please check your email account and click on verify button. if you still not received kindly check your spam box.</p>
                            {/* <div>
                                <p className="sizeverifytext"> Greetings!!!<br></br>
                                Your account is verified. Please submit your preference to serve you better.</p>
                                <button className="logintbn ">Submit Preference</button>
                            </div> */}

                        </div>
                    </div>
                    <div className="formlogin" id="expoInfoPopup" style={{display: 'none'}}>
                        <div className="headerlogin headerlogin1">
                            {/* <h3 className="logintitle">Account Verification</h3> */}
                            <h4 className="logintitle">Account Verified</h4>

                        </div>
                        <div className="formcontainer  formcontainer1">
                            {/* <p className="sizeverifytext">
                                We have sent you an activation link to your registered email address.<br></br><br></br> Please check your email account and click on verify button.</p> */}
                            <div>
                                <p className="sizeverifytext"> Greetings!!!<br></br>
                                Your account is verified. Please submit your preference to serve you better.</p>
                                <button className="logintbn " onClick={() => window.location.href="/"}>Submit Preference</button>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />

                </div>

            </div>
        )
    }
}
