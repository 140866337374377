/* eslint-disable */

/**
 * Project Background Iframe component
 */

import React, { Component } from "react";
import "./background.css";

export default class BackGround extends Component {
  render() {
    return (
      <div>
        <iframe
          className="iframeposition"
          allowvr="yes"
          scrolling="no"
          title=""
          id="projectBackground"
          src={this.props.iframePath}
        ></iframe>
      </div>
    );
  }
}
