let FilterData = [{
    "projectid": "600c10695944bb00114a8c1d",
    "companyName": "DHARMANANDAN TECHNO PROJECT PVT LTD",
    "description": "WATER TREATMENT PLANT , MINERAL WATER PLANT, SOFT DRINK PLANT , JUICE PLANT AUTOMATIC BOTTELING MACHINE, BOTTLE BLOW MOULDING MACHINE, HEAT SHRINK PACKING MACHINES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/1.png",
    "category":"Mineral Water Plant,Soft Drink Plant,Juice Plant,Blow Moulding Machine,Bottle Filling Machine"
  },
  {
    "projectid": "600c12225944bb00114a8e74",
    "companyName": "RACHIT GROUP",
    "description": "PROTECTIVE FACE MASKS,SAREES, LEHENGAS , FABRICS",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/2.png",
    "category":"Fabrics,Sarees,Lehengas,Face Masks"
      
  },
  {
    "projectid": "600c0fa25944bb00114a8b7f",
    "companyName": "GOKUL TEXTILES",
    "description": "SAREES,BLOUSES,KURTIS,SUITS,",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/3.png",
    "category":"Saree,suits,Kurtis,blouses,lehengas"
  },
  {

    "projectid": "600c0f0d5944bb00114a8ae3",
    "companyName": "SEA HORSE",
    "description": "AGRICULTURE SECTOR",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/4.png",
    "category":""
  },
  {
    "projectid": "600c117d5944bb00114a8d9a",
    "companyName": "NOBLE EXPORTS",
    "description": "HOTEL/MOTEL SUPPLIES,FRUITS,VEGETABLES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/5.png",
    "category":"Hotel/Motel Supplies,Fresh Fruits,Fresh Vegetables"
  },
  {
    "projectid": "600c0fd25944bb00114a8bbb",
    "companyName": "FASHION PLUS",
    "description": "LACE,FABRIC",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/6.png",
    "category":"Lace,Fabrics"
  },
  {
    "projectid": "600c13825944bb00114a9013",
    "companyName": "ALLIGN OVERSEAS",
    "description": "2D AND 3D MDF PANELS, STONES(MARBLE , GRANITE , SAND STONE),TILES,ROPES,RICE,SPICES.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/7.png",
    "category":"Stones & Tiles,Ropes,Spices,Rice,2D & 3D MDF Panels"
  },
  {
    "projectid": "600c0f465944bb00114a8b24",
    "companyName": "TRIVASHI INTERNATIONAL EXPORT PVT LTD.",
    "description": "ALLUMINIUM INGOTS,NUT - BOLTS, DIAMONDS,FRESH FRUITS AND VEGETABLES , FABRICS, CHEMICAL FOR PAPER INDUSTRY.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/8.png",
    "category":"aluminium ingots,nut and bolt"
  },
  {
    "projectid": "600c10945944bb00114a8c59",
    "companyName": "SRRS GLOBAL",
    "description": "FOOD AND AGRO,TEXTILE, SALON FURNITURE AND PRODUCTS.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/9.png",
    "category":""
  },
  {
    "projectid": "600c12f55944bb00114a8f72",
    "companyName": "SHREEJI EXPORTS",
    "description": "PERSONALSED BOXES, STATIONERY PRODUCTS, COPPER PRODUCTS,FIBER PILLOWS,JUTE/NON-WOVEN/PAPER BAGS, PUFFED RICE & FLATTENED RICE.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/10.png",
    "category":"Personalised boxes,Stationery,Copper Products,Paper, Nonwoven & jute Bags,Fiber Pillows and comforters"
  },
  {
    "projectid": "600c10525944bb00114a8bff",
    "companyName": "DREAMZ WORLD",
    "description": "HAND MADE CARPET , BAGS & TRAVEL ACCESSORIES , FOOD PRODUCTS, ENGINEERING PRODUCTS(WEILDING ELECTRODE , NUT-BOLTS & FASTENERS).",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/11.png",
    "category":"Hand knotted carpets,paintings and Stationary,Bags and Luggage,Food products,Engineering products"
  },
  {
    "projectid": "600c0f305944bb00114a8b05",
    "companyName": "AARAVI INTERNATIONAL",
    "description": "FURNITURE & DECOR INDUSTRY , NON WOVEN HEIGYNE INDUSTRY ,TEXTILE INDUSTRY,CLEANING CHEMICAL INDUSTRY,STATIONERY & PAPER PRINTING INDUSTRY",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/12.png",
    "category":"Fabrics,Chemicals,Furniture,Printing Stationery,T-shirts"
  },
  {
    "projectid": "600c11ae5944bb00114a8dda",
    "companyName": "OMNIPRESENCE OVERSEAS",
    "description": "AGRICULTURE & FOOD PRODUCTS, SPICES,BAMBOO PRODUCTS , CANE PRODUCTS & FURNITURE, ECO FRIENDLY PACKAGING MATERIAL , DISINFECTANT & WATER TREATMENT RELATED PRODUCTS.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/13.png",
    "category":"Agriculture food products,Bamboo and cane products,Spices,Packaging materials,Water treatment related products"
  },
  {
    "projectid": "600c10aa5944bb00114a8c7a",
    "companyName": "KASIDA FASHION STUDIO LLP",
    "description": "PLAIN, DYED AND PRINTED FABRICS, FANCY FABRICS , EMBROIDERY & JAQUARD, LACES & BORDERS, GARMENTS,IT SERVICES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/14.png",
    "category":""
  },
  {
    "projectid": "601172df5353c600113f3bfb",
    "companyName": "SRI SAANJA",
    "description": "ALL KIND OF TEXTILE FABRIC PRINTING & DYING TEXTILES, HYGIENIC PRODUCT,ORGANIC GHEE,MANGO PULP & POWDER,JAGGERY.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/Sri_Saanja-logo+JPEG.jpg",
    "category":"Textile and Fabrics Manufacturer,Organic Food Manufacturer,Flag Manufacturer,Apparels Manufacturer,Home Designs Manufacturer,Accessories Manufacturer,Hygiene Product Manufacturer"
  },
  {
    "projectid": "600c0f725944bb00114a8b60",
    "companyName": "HONEY LACE",
    "description": "EMBROIDERY LACE & TRIMMING MATERIAL , EMBROIDERY FABRICS, JACQUARD FABRICS, METALLIC YARN & VISCOSE FABRIC",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/16.png",
    "category":"LACE & TRIMMING MATERIALS,EMBROIDERY FABRICS,JACQUARD FABRICS,VISCOSE FABRICS,METALIC YARN"
  },
  {
    "projectid": "600c12515944bb00114a8eb0",
    "companyName": "GALORE IMPEX",
    "description": "ROPES , SACKS & BAGS,RICE",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/17.png",
    "category":"Plastics,Packaging Ropes,Hdpe & PP Ropes,Hdpe & PP Sacks & bags,5FIBC"
  },
  {
    "projectid": "600c10dc5944bb00114a8cb8",
    "companyName": "ZYPEX",
    "description": "FURNITURE , FOOD PRODUCTS, TEXTILES, CERAMIC TILES, BICYCLE , PLASTIC HOME UTILITY PRODUCTS",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/18.png",
    "category":"Furniture,Ceramic tiles,Rice, cereals and pulses,Instant mix food products,Spices,Ready to eat food products,Bakery products,Dyed twisted yarns,Denim,Mattresses, rugs, carpets,Bed and bath linen,Polyester fabric,Bicycles,Plastic furniture & plastic home utility products,Fruits & Vegetables"
  },
  {
    "projectid": "600c123d5944bb00114a8e92",
    "companyName": "SCALE WORK",
    "description": "STONES,CERAMIC TILES,SANITARY WARE, BATH FITTINGS, TERRACOTTA PRODUCTS, CONSTRUCTION CHEMICALS, WPC MATERIALS.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/logo+scalework.png",
    "category":""
  },
  {
    "projectid": "600c11e25944bb00114a8e19",
    "companyName": "KHULLAR IMPEX",
    "description": "FRESH FRUITS,GARLIC ,GINGER, HOME DECOR HANDICRAFTS,MATTRESSES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/20.png",
    "category":"Fresh Fruits,Ginger And Garlic,Handicrafts Home Decor,Mattresses"


  },
  {
    "projectid": "600c10f05944bb00114a8cd6",
    "companyName": "DIVENCE TECHNO PVT LTD.",
    "description": "METAL SPRINGS, SHEET METAL COMPONENTS , PLASTIC MOULD COMPONENTS, ELECTRIC CABLE TRAY (MS,GI,SS) ,PRESS TOOLS ,INJECTION MOULDING TOOLS",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/21.png",
    "category":"Metal springs,sheet metal components,plastic moul components,press tools,injection molding tools"

  },
  {
    "projectid": "600c133b5944bb00114a8fb2",
    "companyName": "SV EXPORTS",
    "description": "INDIAN TEA , SPICES, RICE, MILLED PRODUCTS, TEXTILE FABRICS , READYMADE GARMENTS (WOMEN)",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/22.png",
    "category":"Indian Tea,Salt (Edible & Industrial),Milled Products (Flours),Spices,Textile Fabric & Garments"

  },
  {
    "projectid": "600c11c55944bb00114a8df8",
    "companyName": "SINGA EXPORTS",
    "description": "TEXTILE PRODUCTS, AGRO , BAKERY PRODUCTS",
    // "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/24.png"
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/23.png",
    "category":"Elastic Tapes,Laces, Trimmings & Fringes,Agricultural Products Rice, Spice,Bakery Products"
  },
  {
    "projectid": "600c111c5944bb00114a8d13",
    "companyName": "SURYA IMEX",
    "description": "PULSES, PEANUTS,DEHYDRATED PRODUCTS, FRUITS , VEGETABLES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/24.png",
    "category":""
  },
  {
    "projectid": "600c126b5944bb00114a8ed0",
    "companyName": "RH ENTERPRISE",
    "description": "FOOD GRAIN & SPICES, FANCY BAGS, COTTON NIGHT WEAR, COSMETIC CREAM AND SOAP BAR",
    // "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/26.png"
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/25.png",
    "category":"Foodgrains & Spices,Beauty Creams,Cotton Night wear,Bags,Fruits"
  },
  {
    "projectid": "600c13535944bb00114a8fd4",
    "companyName": "SPIDER OVERSEAS",
    "description": "SNACKS,SPICES,RICE,QUILT AND BEDSHEETS , LADIES DRESSES.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/26.png",
    "category":"Namkeens,spices,Ladies dresses (kurties),Rice,Quilt"
  },
  {
    "projectid": "600c0ee85944bb00114a8abe",
    "companyName": "AKSHAR INDUSTRY",
    "description": "POLYESTER FABRIC (DIGITAL PRINT WITH FOILS) , VISCOSE FABRIC (DIGITAL PRINT WITH FOILS)  VISCOSE FABRIC (DYED & FOILS) LIVA FABRIC (DYED & FOIL)",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/27.png",
    "category":"textile / Garnent fabrics,out door sports gears,elastic tape"
  },
  {
    "projectid": "600c11345944bb00114a8d33",
    "companyName": "AMBUJA IMPEX",
    "description": "TEA,COFFEE,SPICES, KHAKHRA, CEREALS & PSYLLIUM HUSK",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/28.png",
    "category":"Spices,Tea,Coffee,Cereals and Psyllium,Khakhra"
  },
  {
    "projectid": "600c120c5944bb00114a8e55",
    "companyName": "NIRMIT INTERNATIONAL",
    "description": "MATTRESS PROTECTOR , WATER PROOF APPRONS, BEDSHEETS, BATH LINEN , HOTEL BED LINEN",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/29.png",
    "category":"Mattress Protector,Aprons,Bed Sheet,Bath Linen,Hotel Bed Linen"
  },
  {
    "projectid": "600c12df5944bb00114a8f52",
    "companyName": "URMILA INTERNATIONAL",
    "description": "TEXTILE FABRICS , GARMENTS , LADIES WEAR , SAREES, FRESH FRUITS & VEGETABLES, SPICES , RICE (BASMATI & NON BASMATI), EDIBLE OIL, SNACKS (PAPAD , KHAKHRA , FRYUMS).",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/30.png",
    "category":"Textiles,Fruits & Vegetables,Spices,Rice,Edible Oil"
  },
  {
    "projectid": "600c12a35944bb00114a8f0c",
    "companyName": "AKKI INTERNATIONAL",
    "description": "FABRICS & GARMENTS , FRUITS & VEGETABLES , GOLD , SILVER & IMITATION JEWELERY , SPICES , DRY FRUITS.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/31.png",
    "category":"Fabrics and garments,Fresh fruits and vegetables,Gold silver and imitation jewelry,Spices,Dry Fruits"
  },
  {
    "projectid": "600c12ba5944bb00114a8f2a",
    "companyName": "MAHAYANA INTERNATIONAL",
    "description": "TEXTILE, FRUITS & VEGETABLES, GRAINS , SPICES , NON BASMATI RICE",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/32.png",
    "category":"fruits,Vegetable,Food Products,Grains,Textile,Spices,RICE"
  },
  {
    "projectid": "600c0ba55944bb00114a8a4c",
    "companyName": "PANCHAL MERCHANT PVT LTD",
    "description": "CHEMICALS,PHARMA DRUGS,SPICES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/IMG_20210117_095203+(2)+(1).JPG",
    "category":"Chemicals,ready to eat meal,spices,plastic flexible packaging,Pharma bulk drugs"
  },
  {
    "projectid": "600c0f5d5944bb00114a8b42",
    "companyName": "3TEE ELASTIC PVT LTD",
    "description": "ELASTICS TAPES , WRIST SUPPORT, NAME TAPES, POLO NECK T-SHIRTS, HOODIES/ SWEAT SHIRTS, PRINTED CANVAS BAGS, JHALAR LACE",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/34.png",
    "category":"Elastic Tapes,Wrist Support,Polo Tshirt,Men Hoodie,Printed Canvas Bags,Jhalar Lace,Name Polyester Collar / Neck Tape"
  },
  {
    "projectid": "600c0fb95944bb00114a8b9d",
    "companyName": "MK DIAMONDS",
    "description": "DIAMOND JEWELERY",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/35.png",
    "category":"diamonds,diamondanjwellery,handicraft,lab grown"
  },
  {
    "projectid": "600c103c5944bb00114a8be1",
    "companyName": "OUTDARE",
    "description": "SEAFOOD,CEREAL GRAIN,OILSEED,SPICES,VEGETABLES,PHARMACEUTICALS,ORGANIC CHEMICALS,",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/36.png",
    "category":"Seafood,Rice & Other Cereal Grains,Peanuts & Other Oilseeds,Dry Red Chilli & Other Spices,Vegetables,Pharmaceuticals"
  },
  {
    "projectid": "600c107c5944bb00114a8c3b",
    "companyName": "CLASSIC GLOBAL",
    "description": "FABRICS , READYMADE GARMENTS , BASMATI RICE & NON BASMATI RICE, PAPER , SUGAR",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/37.png",
    "category":"Fabrics,Ready Made Garments,Rice,Paper,Sugar"
  },
  {
    "projectid": "600c10c35944bb00114a8c9a",
    "companyName": "JASVISH ENTERPRISES",
    "description": "CONDOMS , HOME TEXTILE,TEXTILE,SPICES,AGRICULTURAL,FRUITS & VEGETABLES,TILES,BRASS PRODUCT, FLOUR MILL, SANITARY WARES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/38.png",
    "category":"Condoms,Textile,Home textile,Fruit & Vegetables,Spices & Agricultural products,Tiles & Sanitary wares,Brass products,Flour mill,Diapers"
  },
  {
    "projectid": "600c11055944bb00114a8cf5",
    "companyName": "TUFF ROCK INDUSTIRES",
    "description": "CONSTRUCTION CHEMICALS, AGRO SHED PLASTIC PRODUCTS , FOOD PRODUCTS",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/39.png",
    "category":"Construction chemicals,Agro shed,Plastic products,Food products,Paint"
  },
  {
    "projectid": "600c11495944bb00114a8d51",
    "companyName": "MAHAVIR PLASTIC",
    "description": "PLASTIC MOULDED FURNITURES, GAS STOVES,MIXER GRINDERS,ALLUMINIUM LADDERS.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/SEEMA+GOLD+LOGO++PNG.png",
    "category":"Spices,Beans,Food products,Hardware products,Tiles"
  },
  {
    "projectid": "600c11645944bb00114a8d75",
    "companyName": "SANKALP EXPORTS",
    "description": "SPICES,BEANS,HONEY,GHEE,PAPAD,KHAKHRA,HARDWARE,TILES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/41.png",
    "category":"Spices,Beans,Food products,Hardware products,Tiles"
  },
  {
    "projectid": "600c11955944bb00114a8dba",
    "companyName": "GLAMSTAR DIAMONDS",
    "description": "NATURAL DIAMONDS, LAB GROWN DIAMONDS",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/42.png",
    "category":"Natural diamonds,Lab-grown diamonds,diamonds jewelery" 
  },
  {
    "projectid": "600c11f75944bb00114a8e37",
    "companyName": "TIZARA GROUP",
    "description": "TEXTILE & AGRICULTURE",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/43.png",
    "category":"Fabrics,Tshirts,Bed linen,Hospitality industry,Rice,Wheat flour,Maize"
  },
  {
    "projectid": "600c12875944bb00114a8eee",
    "companyName": "AKB HERBALS PVT LTD.",
    "description": "PERSONAL CARE PRODUCTS (FACE WASH , SOAP,SHAMPOO, HAIR COLOR, HAIR PACK, ROSE WATER, ROSE MIST, FACE PACK.",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/AKB+Herbal+(1).PNG",
    "category":"personal care products,beauty products,cosmetics"
  },
  {
    "projectid": "600c131b5944bb00114a8f92",
    "companyName": "LOTUS INTERNATIONAL",
    "description": "BIODEGRADABLE PLASTIC BAGS, BIO ORGANIC FERTILIZER,TURMERIC",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/45.png",
    "category":"Biodegradable Plastic Bag,Organic Fertiliser,Turmeric Powder,Desi Chana,Onion,Dehydrated Onion,Dehydrated Garlic"
  },
  {
    "projectid": "600c13685944bb00114a8ff5",
    "companyName": "KISINISI CORPORATION",
    "description": "TEXTILE, HYEGINE AND PERSONAL CARE, BUTYL INNER TUBES",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/46.png",
    "category":"Hygiene,Textile,Butyl inner tubes"
  },
  // {
  //   "projectid": "601172915353c600113f3bdc",
  //   "companyName": "BOT BABA",
  //   "description": "CHAT BOTS",
  //   "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/47.png",
  //   "category":[]
  // },
  {
    "projectid": "601172755353c600113f3bbd",
    "companyName": "LOAN TALK",
    "description": "EXPORT IMPORT FINANCE",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/WhatsApp+Image+2021-01-29+at+11.41.07+AM.jpeg",
    "category":""
  },
  {
    "projectid": "6012a74d584ca900113ae78a",
    "companyName": "Ashish Bafna",
    "description": "",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/003+(1).png",
    "category":""
  },
  {
    "projectid": "6012a649584ca900113ae72c",
    "companyName": "Hardik Social",
    "description": "",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/19.jpg",
    "category":""
  },
  {
    "projectid": "60379aa14d59ce00184751de",
    "companyName": "Kushang Textiles",
    "description": "",
    "link": "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/being-exporter/KUSHANG.png",
    "category":""
  },
]


export default FilterData;