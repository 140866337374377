// import Mainpage from './components/mainpage/mainpage'
// import './App.css '
// import Registration from './components/registration/registration'
// import Login from './components/login/login';
import Bottommenu from './components/bottommenu/bottommenu';
import Footer from './components/footer/footer';
// import BackGround from './components/background/background';
import React from 'react'
import RouterContainer from "./router-container";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

class App extends React.Component {
  componentDidMount() {
    
  }
  render() {
    return (
    <div className="App">
      {/* <Mainpage/> */}
      <Router history={history}>
        <RouterContainer />
      </Router>
      {/* <BackGround iframePath="https://vr-expo.firebaseapp.com/dekho/5f0c0c13c7134b000f672dd4/0/novr/none" /> */}
      {/* <Bottommenu /> */}
      <Footer />
    </div>
  );
}
}

export default App;
