/*eslint-disable*/
import React, { Component } from 'react'
import lobbyimg from '../../image/being/Archive-3.png'
import exhibitionImg from '../../image/being/exhibition.png'
import auditoriumImg from '../../image/being/auditorium.png'
import searchImg from '../../image/being/search.png'
import vrImg from '../../image/being/vr.png'
import layoutImg from '../../image/being/layout.png'
import logoutImg from '../../image/being/logout.png'
import agendaImg from '../../image/being/AGENDA.png'
import entertainmentImg from '../../image/being/entertainment.png'
import './bottommenu.css';
import './agenda.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import menuimg from '../../image/being/menu.png';
import closeimg from '../../image/being/close.png';
import ANANDimg from '../../image/being/agenda/ANAND NAIDU.jpg';
import BHARATimg from '../../image/being/agenda/BHARAT JETHANI.jpg';
import HARDIKimg from '../../image/being/agenda/HARDIK DAWAR.jpg';
import KIRANPALimg from '../../image/being/agenda/KIRANPAL SINGH CHAWALA.jpg';
import MUNISHimg from '../../image/being/agenda/MUNISH VERMA.jpg';
import MURLIimg from '../../image/being/agenda/MURLI SUNDARAM.jpg';
import NISHAimg from '../../image/being/agenda/NISHA ANAND.jpg';
import NITINimg from '../../image/being/agenda/NITIN BHARATIA.jpg';
import PRATIKimg from '../../image/being/agenda/PRATIK MALHOTRA.jpg';
import SIDHARTHimg from '../../image/being/agenda/SIDHARTH SHAH.jpg';
import TARUNimg from '../../image/being/agenda/TARUN MAKHIJA.jpg';
import VIVEKimg from '../../image/being/agenda/VIVEK TANGRI.jpg';
import Scarlettimg from '../../image/being/agenda/Scarlett.png';
import yadavimg from '../../image/being/agenda/yadav.png';
import francescoimg from '../../image/being/agenda/francesco.png';
import brijeshimg from '../../image/being/agenda/brijesh.png';
import kezyimg from '../../image/being/agenda/kezy.png';
import akashimg from '../../image/being/agenda/akash.png';
import AAkashimg from '../../image/being/agenda/AAkash.jpg';
import Ankitimg from '../../image/being/agenda/Ankit.jpg';
import Badrimg from '../../image/being/agenda/Badr.jpg';
import Elizabethimg from '../../image/being/agenda/Elizabeth.jpg';
import Erickimg from '../../image/being/agenda/Erick.jpg';
import Gauravimg from '../../image/being/agenda/Gaurav.jpg';
import Hardikimg from '../../image/being/agenda/Hardik.jpg';
import Kumaresanimg from '../../image/being/agenda/Kumaresan.jpg';
import luckyimg from '../../image/being/agenda/lucky.jpg';
import Manishimg from '../../image/being/agenda/Manish.jpg';
import Pavelimg from '../../image/being/agenda/Pavel.jpg';
import Tareqimg from '../../image/being/agenda/tareq.jpg';
import Sherifimg from '../../image/being/agenda/sherif.jpg';
import nehalimg from '../../image/being/agenda/nehal.jpg';
import emilioimg from '../../image/being/agenda/emilio.jpg';
import zachyimg from '../../image/being/agenda/zachy.jpg';

import Filter from '../FilterPopup/Filter'
import { isMobile } from '../../functions/firebase'
import ChatPanelCountry from '../chatpanel/ChatPanelCountry'
import firebaseApp from '../chatpanel/chat/firebase-app';
import selectedProjectInfo from '../chatpanel/selectedProjectObj'


export default class Bottommenu extends Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isChatPanelVisible: false,
            chatProjectId: '',
            isProjectChanged: false
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.checkForProjectId, false)
    }


    componentWillUnmount() {
        //6006d9848c5ae500111e8722
        window.removeEventListener('load', this.checkForHashProject);
        window.removeEventListener('message', this.checkForProjectId);
    }

    //   checkForProjectId = (e) => {
    //     if (e.data.isProjectIdExist) {
    //       if (e.data.projectId) {
    //       console.log('project id throght event',e.data.projectId)
    //       let projectid = e.data.projectId
    //       firebaseApp.firestore().collection('StallList').where('projectid','==',projectid).get().then(snapshot=>{
    //         if(snapshot.size>0){
    //           console.log('doc found')
    //           this.setState({
    //             isChatPanelVisible:true,
    //             chatProjectId:projectid,
    //             // isProjectChanged:true

    //           })
    //         }
    //         else{
    //           this.setState({
    //             isChatPanelVisible:false,
    //             chatProjectId:'',
    //             // isProjectChanged:false

    //           })
    //         }
    //       })
    //     }
    //     }

    checkForProjectId = (e) => {
        if (e.data.isProjectIdExist) {
            if (e.data.projectId) {
                console.log('project id throght event', e.data.projectId)
                let projectid = e.data.projectId
                firebaseApp.firestore().collection('StallList').where('projectid', '==', projectid).get().then(snapshot => {
                    if (snapshot.size > 0) {
                        console.log('doc found')
                        this.setState({
                            isChatPanelVisible: true,
                            chatProjectId: projectid
                        })
                    }
                    else {
                        this.setState({
                            isChatPanelVisible: false,
                            chatProjectId: ''
                        },()=>{selectedProjectInfo.projectid=''})
                    }
                })

            }
        }
    }


    openbottommenu = () => {
        document.getElementById("bottommenu").classList.remove("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "none";
        document.getElementById("Mobmenuclose").style.display = "flex";
        if ((document.getElementById("BOTTTOMchatbox"))) {

            document.getElementById("BOTTTOMchatbox").classList.remove("bottomchatbox1");
        }
        if ((document.getElementById("message-form"))) {

            document.getElementById("message-form").classList.remove("chat-textnormalani");
        }

    }
    closebottommenu = () => {
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if (document.getElementById("BOTTTOMchatbox"))
            document.getElementById("BOTTTOMchatbox").classList.add("bottomchatbox1");
        if (document.getElementById("message-form"))
            document.getElementById("message-form").classList.add("chat-textnormalani");

    }


    logoutClick = () => {
        window.localStorage.clear();
        window.location.href = '/';
    }

    goToAgenda = () => {
        document.getElementById('location1').style.display = 'block'
    }

    closePopup = () => {
        document.getElementById('location1').style.display = 'none'
    }

    updateStateValue = () => {
        this.props.changeValueForState(true, false)
    }

    goToExhibition = () => {
        this.props.changeValueForState(true, false)
        console.log('Should go to Exhibition');
        localStorage.setItem('location', 'exhibition')
        // this.props.changeValueForState(true,false)
        setTimeout(() => {
            if (document.getElementById('projectBackground')) {
                const iframeEl = document.getElementById("projectBackground");
                const projectInfo = {
                    isExpoHomeClicked: true,
                    projectId: '5ffd314d0ea14a0011e33505',
                    sceneIndex: "1",
                };
                iframeEl.contentWindow.postMessage(projectInfo, "*");
                selectedProjectInfo.projectid = ''

                //   console.log('selected project id ---- 5ffd314d0ea14a0011e33505', )
                // document.getElementById('projectBackground').setAttribute('src', 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/1/novr/none');
            }
        }, 500);
    }

    goTOEntertainment = () => {
        this.props.changeValueForState(true, false)
        console.log('Should go to entertainment');
        localStorage.setItem('location', 'entertainment')
        // this.props.changeValueForState(true,false)
        setTimeout(() => {

            if (document.getElementById('projectBackground')) {
                const iframeEl = document.getElementById("projectBackground");
                const projectInfo = {
                    isExpoHomeClicked: true,
                    projectId: '5ffd314d0ea14a0011e33505',
                    sceneIndex: "2",
                };
                iframeEl.contentWindow.postMessage(projectInfo, "*");
                selectedProjectInfo.projectid = ''

                // document.getElementById('projectBackground').setAttribute('src', 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/2/novr/none');
            }
        }, 500);
    }



    goToAuditorium = () => {

        console.log('Should go to Auditorium');
        // this.props.changeValueForState(false, true)
        // localStorage.setItem('location', 'webinar')
        // // if (document.getElementById('projectBackground')) {
        // //     document.getElementById('projectBackground').setAttribute('src', 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/0/novr/none');
        // // }
        // window.location.href = '/auditorium'
        setTimeout(() => {

        if (document.getElementById('projectBackground')) {
            const iframeEl = document.getElementById("projectBackground");
            const projectInfo = {
                isExpoHomeClicked: true,
                projectId: '5ffd314d0ea14a0011e33505',
                sceneIndex: "15",
            };
            iframeEl.contentWindow.postMessage(projectInfo, "*");
            selectedProjectInfo.projectid = ''

            // document.getElementById('projectBackground').setAttribute('src', 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/2/novr/none');
        }
    }, 500);


    }

    changeAgendaDate = (activeTabNum) => {
        console.warn('Should open tab', activeTabNum);
        for (let i = 1; i <= 3; i++) {
            document.getElementById('tab-' + i).style.display = 'none';

            if (document.getElementById('tab-' + i).classList.contains('tab-current')) {
                document.getElementById('tab-' + i).classList.remove('tab-current');
            }

            if (document.getElementById('btn-' + i).classList.contains('tab-active')) {
                document.getElementById('btn-' + i).classList.remove('tab-active');
            }
        }
        document.getElementById('tab-' + activeTabNum).style.display = 'block';
        document.getElementById('tab-' + activeTabNum).classList.add('tab-current');
        document.getElementById('btn-' + activeTabNum).classList.add('tab-active');
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false
        })
    }

    changeProjectId = (projectId) => {


        // window.addEventListener('message',(e)=>{
        //     console.log('event data',e.data)
        //     // if(e.data.projectId == )


        // })
        this.setState({
            isProjectChanged: !this.state.isProjectChanged,
            isChatPanelVisible: false
        })


    }


    openModal = () => {
        this.setState({
            modalIsOpen: true
        })
    }

    vrButtonClick = () => {
        if (isMobile()) {
            let myURL = 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/0/vr/none'
            window.open(myURL, '_blank');
        } else {
            document.getElementById("overlayVR").style.display = "block";
            document.getElementById("popupVR").classList.add("toptobottom");
        }
    }

    closeVRPopup = () => {
        document.getElementById("overlayVR").style.display = "none";
        document.getElementById("popupVR").classList.remove("toptobottom");
    }

    render() {

        return (
            <div>
                <div className="mobmenu" id="Mobmenu">
                    <img src={menuimg} className="widthmenuicon" alt="" onClick={this.openbottommenu} />
                </div>
                <div className="mobmenuclose" id="Mobmenuclose">
                    <img src={closeimg} className="widthmenuicon" alt="" onClick={this.closebottommenu} />
                </div>
                <div className="layerform" id="overlayVR" style={{ display: 'none' }} onClick={this.closeVRPopup}>

                </div>
                <div className="smallpopupposition" id="popupVR">

                    <div className="formlogin" style={{ display: 'block' }}>
                        <div class="headerlogin headerlogin1">
                            <h4 class="logintitle">VR</h4>
                            <span className="closeButton" onClick={this.closeVRPopup}>&times;</span>
                        </div>
                        <div class="formcontainer  formcontainer1">
                            <p class="sizeverifytext">
                                This features is not available in desktop version
                                </p>
                            <br />
                        </div>
                    </div>
                </div>
                <div id="bottommenu" className="bottomaniamtion">
                    <ul className="list-unstyled  flexicon scrolltypewhite">
                        <li className="flexcolumn" onClick={this.openModal}>
                            <img src={searchImg} className="widthiconmenu" alt="Gallary" />
                            <span className="cenetrtext bottomtext">Search</span>
                        </li>
                        <li className="flexcolumn" onClick={this.goToExhibition}>
                            <img src={exhibitionImg} className="widthiconmenu" alt="Webinar" />
                            <span className="cenetrtext bottomtext">Lobby</span>
                        </li>
                        <li className="flexcolumn" onClick={this.goToAuditorium}>
                            <img src={auditoriumImg} className="widthiconmenu" alt="Webinar" />
                            <span className="cenetrtext bottomtext">Auditorium</span>
                        </li>
                        <li className="flexcolumn" onClick={this.goTOEntertainment}>
                            <img src={entertainmentImg} className="widthiconmenu" alt="entertainment" />
                            <span className="cenetrtext bottomtext">Entertainment Zone</span>
                        </li>

                        {/* <li className="flexcolumn" onClick={this.openModal}>
                      <img src={searchImg} className="widthicon" alt="Gallary" />
                    </li>

                     */}
                        <li className="flexcolumn" onClick={this.vrButtonClick}>
                            <img src={vrImg} className="widthiconmenu" alt="Gallary" />
                            <span className="cenetrtext bottomtext">VR</span>
                        </li>
                        {/* <li className="flexcolumn" onClick={this.goToDisplayGallarySection}>
                      <img src={layoutImg} className="widthiconmenu" alt="Gallary" />
                      <span className="cenetrtext bottomtext">Layout</span>
                  </li> */}
                        <li className="flexcolumn" onClick={this.goToAgenda}>
                            <img src={agendaImg} className="widthiconmenu" alt="Gallary" />
                            <span className="cenetrtext bottomtext">Guest & Webinars</span>
                        </li>

                        <li className="flexcolumn" onClick={this.logoutClick} >
                            <img src={logoutImg} className="widthiconmenu" alt="Log out" onClick={this.userLogout} />
                            <span className="cenetrtext bottomtext">Log Out</span>

                        </li>
                    </ul>
                </div>
                {/* AGENDA POPUP */}
                <div className="modal" id="location1" style={{ display: 'none' }} >
                    <div className="modal-dialog modal-dialog-centered modal-xl" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Guest & Webinars</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={this.closePopup}>&times;</button>
                            </div>
                            {/* <div className="agendaButton">
                          <button className="btnBlueGreen active" id='agendaNurseBtn' onClick={this.nurseAgenda}>1</button>
                          <button className="btnBlueGreen margin" id='agendaNeurologistBtn' onClick={this.neurologistAgenda}>2</button>
                      </div> */}

                            <div className="agendaImage" id='nurseAgenda' style={{ display: 'none' }}>
                                <img src={menuimg} style={{ width: '100%' }} />

                            </div>

                            <section id="agenda">
                                <div class="extra-container" style={{ display: 'none' }}>
                                    <div class="schedule-list__tittle">
                                        <h1>Conference Schedule</h1>
                                    </div>
                                </div>
                                <div class="container">
                                    {/* <div class="schedule-tab">
                                        <a class="schedule-tab__item tab-active" id="btn-1" data-tab="tab-1" href="#tab-1" onClick={() => this.changeAgendaDate(1)}>
                                            <h2>Day 01</h2>
                                            <p>26/02/21</p>
                                        </a>
                                        <a class="schedule-tab__item " id="btn-2" data-tab="tab-2" href="#tab-2" onClick={() => this.changeAgendaDate(2)}>
                                            <h2>Day 02</h2>
                                            <p>27/02/21</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-3" data-tab="tab-3" href="#tab-3" onClick={() => this.changeAgendaDate(3)}>
                                            <h2>Day 03</h2>
                                            <p>28/02/21</p>
                                        </a>

                                    </div> */}

                                    <div id="tab-1" class="schedule-table tab-current">


                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>04:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>THE ART OF STORYTELLING IN THE DIGITAL WORLD</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={HARDIKimg} alt="HARDIK DAWAR " />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>HARDIK DAWAR </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>06:00pm </h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>IGNITE YOUR BURNING DESIRE - HOW TO THINK AND GROW IN 2021</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={SIDHARTHimg} alt="SIDHARTH SHAH" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>SIDHARTH SHAH</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>ATTITUDE IS EVERYTHING</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={ANANDimg} alt="ANAND NAIDU " />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>ANAND NAIDU </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* second day speaker */}
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>01:00pm </h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>GIVERS GAIN MORE </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={KIRANPALimg} alt="KIRANPAL SINGH CHAWALA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>KIRANPAL SINGH CHAWALA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>04:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>LEAD GENERATION STRATEGIES USING DIGITAL PLATFORM</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={NITINimg} alt="NITIN BHARATIA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>NITIN BHARATIA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>06:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>WHAT DOES YOUR CUSTOMER WANT FROM YOU. AND WHAT YOU NEED TO DELIVER</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={NISHAimg} alt="NISHA ANAND" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>NISHA ANAND</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>08:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>HOW TO LEVARAGE THE POWER OF SOCIAL MEDIA</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={TARUNimg} alt="TARUN MAKHIJA" />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>TARUN MAKHIJA </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* third day speaker */}
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>11:00am </h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>MARKETING & TECHNOLOGY (MARTECH) </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={PRATIKimg} alt="PRATIK MALHOTRA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>PRATIK MALHOTRA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>01:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>MINDFUL OR MIND FULL - 2 WAYS TO STAY FOCUSED AND BECOME SUCCESSFULL </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={MURLIimg} alt="MURLI SUNDARAM " />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>MURLI SUNDARAM </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>04:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>HAPPINESS VACCINE - NEED OF HOUR</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={VIVEKimg} alt="VIVEK TANGRI" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>VIVEK TANGRI</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>06:00pm</h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>VISION 2025</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={BHARATimg} alt="BHARAT JETHANI " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>BHARAT JETHANI  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>BREAKFREE FROM PROCASTINATION</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={MUNISHimg} alt="MUNISH VERMA " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>MUNISH VERMA  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                                   <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(SOUTH AMERICA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Scarlettimg} alt="SCARLETT HOFFMANN " />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>SCARLETT HOFFMANN </p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(SOUTH AMERICA-INDIA)</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={yadavimg} alt="YADAV ASHWANI BISHKARMA  " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>YADAV ASHWANI BISHKARMA </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(EUROPE-INDIA)</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={francescoimg} alt="FRANCESCO MIRIZZI" />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>FRANCESCO MIRIZZI </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(EUROPE-INDIA)</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={brijeshimg} alt="BRIJESHKUMAR AKOLIYA  " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>BRIJESHKUMAR AKOLIYA </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(AFRICA-INDIA)</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={kezyimg} alt="Kezy Mukiri " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>Kezy Mukiri</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(AFRICA-INDIA)</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={akashimg} alt="Akash Sanjay Agarwal" />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>Akash Sanjay Agarwal </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Elizabethimg} alt="Mrs. Elizabeth Njoroge" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p> Mrs. Elizabeth Njoroge</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Badrimg} alt="Badr Alioua" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Badr Alioua</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Ankitimg} alt="Ankit Sharma" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Ankit Sharma</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Manishimg} alt="Manish KAlla" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Manish KAlla</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Kumaresanimg} alt="Mr. Kumaresan Veerapaan" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Mr. Kumaresan Veerapaan</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Hardikimg} alt="Mr. Hardik A. Gaglani" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Mr. Hardik A. Gaglani</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Pavelimg} alt="Pavel Dolgov" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Pavel Dolgov</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Gauravimg} alt="Gaurav Karwa" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Gaurav Karwa</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Erickimg} alt="Gaurav Karwa" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Dr. Erick Rutto</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={luckyimg} alt="Dr.Lucky Mlilo" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Dr. Lucky Mlilo</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(ASIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={AAkashimg} alt="AAkash chudasama" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>AAkash chudasama</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(EGYPT-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Sherifimg} alt="Sherif Kotb" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Sherif Kotb</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(HEBRON-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={Tareqimg} alt="Tareq Jalal Altamimi" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Tareq Jalal Altamimi</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(TANZANIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={zachyimg} alt="Zachy Mbenna" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Zachy Mbenna</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(TANZANIA-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={nehalimg} alt="nehal shah" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>nehal shah</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="row wow flipInX">
                                           
                                           <div class="col-lg-9 col-sm-12">
                                               <div class="schedule-table__event">
                                               <h3>EXPLORING GLOBAL BUSINESS OPPORTUNITIES(BRAZIL-INDIA)</h3>

                                               </div>
                                           </div>
                                           <div class="col-lg-3 col-sm-12">
                                               <div class="schedule-table__speaker">
                                                   <div class="schedule-table__speaker__info">
                                                       <img src={emilioimg} alt="Emilio mendonca dias da silva" />

                                                       <div class="schedule-table__speaker__name">
                                                           <p>Emilio mendonca dias da silva</p>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                    </div>
                                    <div id="tab-2" class="schedule-table">


                                        <div class="row wow flipInX">
                                            {/* <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>01:00pm </h3>
                                                </div>
                                            </div> */}
                                            <div class="col-lg-9 col-sm-12">
                                                <div class="schedule-table__event">
                                                    <h3>GIVERS GAIN MORE </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={KIRANPALimg} alt="KIRANPAL SINGH CHAWALA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>KIRANPAL SINGH CHAWALA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>04:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>LEAD GENERATION STRATEGIES USING DIGITAL PLATFORM</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={NITINimg} alt="NITIN BHARATIA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>NITIN BHARATIA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>06:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>WHAT DOES YOUR CUSTOMER WANT FROM YOU. AND WHAT YOU NEED TO DELIVER</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={NISHAimg} alt="NISHA ANAND" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>NISHA ANAND</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>08:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>HOW TO LEVARAGE THE POWER OF SOCIAL MEDIA</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={TARUNimg} alt="TARUN MAKHIJA" />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>TARUN MAKHIJA </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-3" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>11:00am </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>MARKETING & TECHNOLOGY (MARTECH) </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={PRATIKimg} alt="PRATIK MALHOTRA" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>PRATIK MALHOTRA</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>01:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>MINDFUL OR MIND FULL - 2 WAYS TO STAY FOCUSED AND BECOME SUCCESSFULL </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={MURLIimg} alt="MURLI SUNDARAM " />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>MURLI SUNDARAM </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>04:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>HAPPINESS VACCINE - NEED OF HOUR</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={VIVEKimg} alt="VIVEK TANGRI" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>VIVEK TANGRI</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>06:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>VISION 2025</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={BHARATimg} alt="BHARAT JETHANI " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>BHARAT JETHANI  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>08:00pm</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>BREAKFREE FROM PROCASTINATION</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={MUNISHimg} alt="MUNISH VERMA " />

                                                        <div class="schedule-table__speaker__name">
                                                            <p>MUNISH VERMA  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                            </section>

                        </div>
                    </div>
                </div>

                {/* AGENDA POPUP */}
                <Filter open={this.state.modalIsOpen} onClose={this.closeModal} changeProjectId={this.changeProjectId} />
                {/*chat panel*/}
                {this.state.isChatPanelVisible &&
                    <ChatPanelCountry projectId={this.state.chatProjectId} userId={localStorage.getItem("userDocId")} isProjectChanged={this.state.isProjectChanged} />
                }
            </div>

        )
    }
}
