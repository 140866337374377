import React, { Component } from 'react'
import $ from 'jquery';
import "./chatpanel.css";
import SendImg from "./images/send.png";
import { generateNewUserId, loadAttendeeMessages, loadMessages,loadAdminMessages, loadModeratorMessages, loadOnlyApprovedMessages, saveMessageToDatabase, saveMessageToDatabaseforModerator } from './chat/chat-functions';
import CustomNotification from './notification/notification';

export default class ChatPanelCountry extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            projectId: "",
            shouldShowNotification: false,
        }
    }
    componentWillReceiveProps(){
        this.setState({
            projectId:this.props.projectId
        })
    }
    componentDidMount(){
        this.setState({
            projectId:this.props.projectId
        })
        loadMessages(this.props.projectId,this.props.userId);
        loadAdminMessages(this.props.projectId,this.props.userId);
        $(function () {
            var arrow = $(".chat-head img");
      
            arrow.on("click", function () {
              var src = arrow.attr("src");
      
              $(".chat-body").slideToggle("fast");
              if (
                src ===
                "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
              ) {
                arrow.attr(
                  "src",
                  "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                );
              } else {
                arrow.attr(
                  "src",
                  "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
                );
              }
            });
          });

         



    }

    sendMessage = (e) => {
        e.preventDefault();
        console.log(this.props.projectId)
        let msg = document.getElementById("message-txt").value;
        console.log('typed msg',msg)
        if (msg!=="") {
        // console.warn('Sending', msg);
        saveMessageToDatabase(msg,this.props.projectId);
        // this.setState({ shouldShowNotification: true });
        // setTimeout(() => {
        //     this.setState({ shouldShowNotification: false });
        // }, 6000);
        } else {
        console.warn("Button should be disabled now");
        }
    }

    render() {
        const {projectId} = this.props;
        console.log('changed project id',this.props.isProjectChanged)
        return (
            <>
            {this.state.shouldShowNotification && <CustomNotification msg="Message sent successfully. please wait for approval" />}
            <>
            <div>
                    <div id="BOTTTOMchatbox" className="bottomchatbox" style={{position:this.props.position?this.props.position:'',bottom:this.props.bottom?this.props.bottom:'',right:this.props.right?this.props.right:''}}>
                    <div className="chat-head">
                        <h2>Chat Box</h2>
                        <img
                            src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                            title="Expand Arrow"
                            width="16"
                            onClick={this.Togglechatbox}
                            alt="Expand/Collapse"
                        />
                    </div>
                    <div className="chat-body scrolltype" id="myScrollingChat" style={{ display: 'none' }}>
                        <div className="flexform  " id="messages"></div>
                        <form
                            id="message-form"
                            onSubmit={this.sendMessage}
                            className="chat-textnormal"
                    
                        >
                            <input
                                type="text"
                                className="message-editbox"
                                id="message-txt"
                                placeholder="Enter Message..."
                                autoComplete="off"
                            />
                            <button type="submit" className="sendarrow">
                                <img className="send-arrow-img" src={SendImg} alt="Send" />
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            </>
            </>
        )
    }
}
