import React, { Component } from 'react';
import 'aframe';
import zoomInImg from "../../image/being/zoom-in.png";
import zoomOutImg from "../../image/being/zoom-out.png";
import backImg from "../../image/being/back.png";
import backdrop from '../../image/being/backdrop.png'
import firebase from 'firebase'
import videojs from "video.js";
import LoadingPage from '../loading/loading-page';
import logoimg from "../../image/being/logo.png";
import Footer from '../footer/footer';
import Bottommenu from '../bottommenu/bottommenu';

const expoName = 'being-exporter'
const liveFormat = "application/x-mpegURL";
const webinarSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/events/gbsm-auditorium.jpg';

export default class Auditorium extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: true,
            isBtnShow: false,
        }
    }

    componentDidMount() {
        this.getUserDetails();
        let self = this;
        // window.AFRAME.registerComponent("sky-webinar-component", {
        //     init: function () {
        //         let el = this.el;
        //         el.addEventListener("loaded", (e) => {
        //             console.log('sky showed')
        //             self.setState({
        //                 isBtnShow: true,
        //             })
        //         });
        //     },
        // });
setTimeout(() => {
    this.setState({isBtnShow:true})
}, 3000);

        videojs('video-player').src({
            type: liveFormat,
            src: 'https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8',
        });
    }

    playLiveVideo = () => {

    }

    getUserDetails() {
        let self = this;
        const db = firebase.firestore();
        const userCollection = db.collection('BeingLive');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        userCollection.where('expo', '==', expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.doc.data().poster === 'true') {
                        document.getElementById('posterForWeibinar').setAttribute('visible', 'true')
                        document.getElementById('webinarVideo').setAttribute('visible', 'false')


                    } else if (change.doc.data().poster === 'false') {
                        document.getElementById('posterForWeibinar').setAttribute('visible', 'false')
                        document.getElementById('webinarVideo').setAttribute('visible', 'true')

                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                        // if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().poster === 'true') {
                            document.getElementById('posterForWeibinar').setAttribute('visible', 'true')
                            document.getElementById('webinarVideo').setAttribute('visible', 'false')

                        } else if (change.doc.data().poster === 'false') {
                            document.getElementById('posterForWeibinar').setAttribute('visible', 'false')
                            document.getElementById('webinarVideo').setAttribute('visible', 'true')

                        }

                        // }
                    }

                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });

    }

    toggleCameraView = () => {
        // console.warn('Change Camera View');
        if (document.querySelector('#webinarCamera').getAttribute('fov') === '62') {
            // console.warn('Near');
            document.querySelector('#webinarCamera').setAttribute('fov', '80');
            document.getElementById("Zoom-In").setAttribute('visible', 'true')
            document.getElementById("Zoom-Out").setAttribute('visible', 'false')
        } else {
            // console.warn('Far');
            document.querySelector('#webinarCamera').setAttribute('fov', '62');
            document.getElementById("Zoom-Out").setAttribute('visible', 'true')
            document.getElementById("Zoom-In").setAttribute('visible', 'false')
        }
    }

    goBackToLobby = () => {
        this.props.changeValueForState(true,false)
        setTimeout(() => {
            if (document.getElementById('projectBackground')) {
                document.getElementById('projectBackground').setAttribute('src', 'https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/1/novr/none');
            }
        }, 100);
    }

    changeStateForLoader = (value) => {
        videojs('video-player').play();
        this.setState({ isloading: value })
    }

    render() {
        return (
            <div>
                <div className="logoposition">
                    <img src={logoimg} alt="logo" className="widthbeinglogo" />
                </div>
                {this.state.isloading &&
                    <LoadingPage updateStateForLoader={this.changeStateForLoader} btnStatus={this.state.isBtnShow} />}
                <a-scene loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                    <a-assets>
                        <img id="sky" crossOrigin="anonymous" src={webinarSky} alt="background" />
                        <img id="webinarPoster" src={backdrop} alt='backdrop' />
                        <video
                            id="video-player"
                            className="video-js vjs-default-skin video2dHtmlEl"
                            crossOrigin="anonymous"
                            loop
                            webkit-playsinline="true"
                            playsInline
                            src="">
                        </video>

                        <img id="backArrowwebinar" src={backImg} alt='back' />
                        <img id="zoomInBtn" src={zoomInImg} alt="Zoom In Button" />
                        <img id="zoomOutBtn" src={zoomOutImg} alt="Zoom Out Button" />
                    </a-assets>
                    <a-camera c-zoom-in-out="defaultFov: 80" look-controls="magicWindowTrackingEnabled: false; gyroModeEnabled: false; reverseTouchDrag: true; disableVerticalMotion:true;" id="webinarCamera" fov="80" wasd-controls="enabled:false">
                        <a-entity
                            cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                            raycaster="objects: .raycaster-solid; recursive: false;"
                        />
                    </a-camera>
                    <a-sky sky-webinar-component id="bgImage" src="#sky" rotation="0 5 0" ></a-sky>
                    <a-image src="#backArrowwebinar" id="webinar-back" position="-2 1.6 -1.9" rotation="0 30 0" scale="0.25 0.25 0.25" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                    <a-image src="#zoomInBtn" id="Zoom-In" position="6 0 -12" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                    <a-image src="#zoomOutBtn" id="Zoom-Out" position="6 0 -12" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} visible={false} />
                    <a-image src="#webinarPoster" id='posterForWeibinar' position="0.5 1.95 -20" width="16" height="8" />
                    <a-video id="webinarVideo" look-at="webinarCamera" src="#video-player_html5_api" position="0.5 1.95 -20" width="16" height="8" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                </a-scene>
                {/* <Bottommenu /> */}
                <Footer />
            </div>
        )
    }
}
