import firebase from "firebase";
const axios = require('axios');
let verificationToken = '';

setupfirebase();
function setupfirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyBhDiDYyLJ_xLb3mIs54mu4E08DMD2p9XM",
    authDomain: "vr-expo.firebaseapp.com",
    databaseURL: "https://vr-expo.firebaseio.com",
    projectId: "vr-expo",
    storageBucket: "vr-expo.appspot.com",
    messagingSenderId: "797131676470",
    appId: "1:797131676470:web:66d8dcec7ab3419d8e7197",
    measurementId: "G-6DBKV0FRP0",
  };

  firebase.initializeApp(firebaseConfig);
}
const expoName = "being-exporter";
// export default fire;

export function submitDetails(email, callback, product, category, description) {
  return new Promise((resolveSubmit, rejectSubmit) => {
    const userdetail = firebase.firestore().collection("Users");
    userdetail
      .where("expo", "==", expoName)
      .where("email", "==", email)
      .get()
      .then((snapshots) => {
        if (snapshots.size > 0) {
          snapshots.forEach((orderItem) => {
            let myData = orderItem.data();
            myData.id = orderItem.id;
            updatePreferenceForm(myData, callback, product, category, description).then(result => {
              if (callback === true) {
                sendContactDetailsToAC(myData).then(result => {
                  console.log("Final Console after saving Contact", result);
                  sendDataTo3D(myData.name, myData.countryCode, myData.mobileNumber, myData.email);
                  resolveSubmit('success')
                }).catch(err => {
                  console.error("Error Adding Contact Info", err);
                })
              } else {

                resolveSubmit('success')
              }
            }).catch(error => {
              console.error("Error updating preference form", error);
            })
            resolveSubmit('success')
          });
        }
      });
    console.log("Updated");
  })

}

export function IsExistingUserForRegistration(
  Name,
  Email,
  Country,
  CountryCode,
  MobileNo,
  companyName,
  utm_source,
  utm_medium,
  utm_content,
  utm_term,
  utm_campaign,
  utm_visits,
  utm_initial_referrer,
  utm_landing_pagecdeo,
  utm_last_referrer,
  browser_name,
  browser_platform,
  browser_version,
  ip_address
) {
  return new Promise((resolve, reject) => {
    const expoName = "being-exporter";
    const db = firebase.firestore();
    const userCollection = db.collection("Users");
    let mobileNo = MobileNo;
    MobileNo = parseInt(mobileNo);
    let ExistingUserQuery = new Promise((resolve, reject) => {
      userCollection
        // .where("mobileNumber", "==", MobileNo)
        .where("email", "==", Email)
        .where("expo", "==", expoName)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // console.log(doc.id, " => ", doc.data());
            resolve(doc.id);
          });
          resolve("Not Found");
        })
        .catch(function (error) {
          reject(error);
        });
    });
    ExistingUserQuery.then((result) => {
      if (result === "Not Found") {
        SaveNewUserDetail(
          Name,
          Email,
          Country,
          CountryCode,
          MobileNo,
          companyName,
          utm_source,
          utm_medium,
          utm_content,
          utm_term,
          utm_campaign,
          utm_visits,
          utm_initial_referrer,
          utm_landing_pagecdeo,
          utm_last_referrer,
          browser_name,
          browser_platform,
          browser_version,
          ip_address
        ).then(() => resolve("success"));
        console.log("Not found");
      } else {
        alert("You are already registered.. Please login using email");
        //    document.getElementById("register-btn").removeAttribute("disabled");
      }
    }).catch((error) => {
      console.error("Already existing user check error", error);
    });
  });
}

/**
 * After registration detail constraint check,
 * this function will create a new user on database
 * on Users collection in vr-expo project
 *
 * @param {User Entered name} Name
 * @param {Email of User} Email
 * @param {Mobile Number of User} MobileNo
 * @param {CountryCode of User} CountryCode
 * @param {Company Name of User} company_name
 */

function SaveNewUserDetail(
  Name,
  Email,
  Country,
  CountryCode,
  MobileNo,
  companyName,
  utm_source,
  utm_medium,
  utm_content,
  utm_term,
  utm_campaign,
  utm_visits,
  utm_initial_referrer,
  utm_landing_pagecdeo,
  utm_last_referrer,
  browser_name,
  browser_platform,
  browser_version,
  ip_address
) {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const userCollection = db.collection("Users");
    const currentTime = new Date().getTime();
    verificationToken = getMyRandomToken();
    let mobilenumber = MobileNo;
    let mobileNumberInt = parseInt(mobilenumber);
    let NewUserEntry = new Promise((resolve, reject) => {
      userCollection
        .add({
          name: Name,
          email: Email,
          country: Country,
          countryCode: CountryCode,
          mobileNumber: mobileNumberInt,
          companyName: companyName,
          dateTime: currentTime,
          expo: expoName,
          verifyToken: verificationToken,
          isVerified: false,
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_content: utm_content,
          utm_term: utm_term,
          utm_campaign: utm_campaign,
          utm_visits: utm_visits,
          utm_initial_referrer: utm_initial_referrer,
          utm_landing_pagecdeo: utm_landing_pagecdeo,
          utm_last_referrer: utm_last_referrer,
          browser_name: browser_name,
          browser_platform: browser_platform,
          browser_version: browser_version,
          ipAddress: ip_address,
        })
        .then(function (docRef) {
          console.log("New User Added, ID: ", docRef.data);
          resolve(docRef.id);
        })
        .catch(function (error) {
          // console.error("Error adding document: ", error);
          reject(error);
        });
    });
    sendDataTo3D(Name, CountryCode, MobileNo, Email);
    NewUserEntry.then((result) => {
      localStorage.setItem("registerStatus", true);
      localStorage.setItem("name", Name);
      localStorage.setItem("email", Email);
      localStorage.setItem("mobileno", MobileNo);
      localStorage.setItem("countryCode", CountryCode);

      sendVerificationEmailToUser(Name, Email).then(abc => {
        resolve("success");
      });
      //  IncrementVisitorCounter();
      // CloseRegisterPopup();
      //  IsExistingCartFound();
    }).catch((error) => {
      console.error("New User Entry in Users error", error);
    });
  });
}
function getMyRandomToken() {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function sendDataTo3D(Name, CountryCode, MobileNo, Email) {
  const iframeEl = document.getElementById("projectBackground");
  console.log(
    "FIANL DATA PASS :: ",
    Name + CountryCode + MobileNo + Email + expoName
  );
  let userDetail = {
    name: Name+"-"+Email,
    countrycode: CountryCode,
    mobileno: CountryCode+MobileNo,
    email: Email,
    expo: expoName,
  };
  iframeEl.contentWindow.postMessage(userDetail, "*");
}

function sendVerificationEmailToUser(name, emailToSend) {

  let emailMessage = "<br />" +
    `<p>Dear ${name},</p> <br/>` +
    "<p>You have to click on the below link to verify your account for Global Buyer Seller Meet.</p><br/>" +
    `<p><a href='https://expo.globalbuyersellermeet.com/verify.html#${verificationToken}'>Verify Account</a></p><br/>` +
    "<p></p>" +
    "<p>Regards,<br />" +
    "Bhagirath Goswami. <br />" +
    "Founder <a href='https://globalbuyersellermeet.com/'>Global Buyer Seller Meet</a></p>" +
    "<br />";
  // console.log("Email:: ", tempEmail);
  var obj = {
    useremail: emailToSend,
    message: emailMessage,
    subject: "Account Verification for Global Buyer Seller Meet",
    expoName: expoName,
    senderName: "Global Buyer Seller Meet"
  };

  // http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
      obj,
      {
        headers: {
          // 'x-access-token': token
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then(success => {
      console.log("Verification Link Send Success", success);
      window.location.href = '/verify.html';
      // setTimeout(() => {
      // }, 3000);
    }).catch(error => {
      console.error("Error in Sending Verification Mail", error);
    })
}

function sendWelcomeEmailToUser(emailToSend) {

  let emailMessage = "<br />" +
    "<p>Thank you for registering in Global Buyer Seller Meet. We will be waiting to host you 26th, 27th, & 28th February 2021 from 12 pm (IST) onwards.</p> <br/>" +
    "<p>This 3 days exhibition will be full of opportunities and international exposures. There will be 200 plus organizations showcasing their products, 30 webinars, 6 Panel discussions and Entertainments Zone with live band performances.</p><br/>" +
    "<p>See you there.<br/>" +
    "Cheers !</p><br/>" +
    "<p>Greetings,<br />" +
    "Bhagirath Goswami. <br />" +
    "Founder <a href='https://globalbuyersellermeet.com/'>Global Buyer Seller Meet</a></p>" +
    "<br />";
  // console.log("Email:: ", tempEmail);
  var obj = {
    useremail: emailToSend,
    message: emailMessage,
    subject: "Welcome to Global Buyer Seller Meet",
    expoName: expoName,
    senderName: "Global Buyer Seller Meet"
  };

  // http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
      obj,
      {
        headers: {
          // 'x-access-token': token
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
}

export function checkForVerificationToken(tokenToCheck) {
  const db = firebase.firestore();
  const userCollection = db.collection("Users");
  let ExistingUserQuery = new Promise((resolve, reject) => {
    userCollection
      .where("expo", "==", expoName)
      .where('verifyToken', '==', tokenToCheck)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let docData = doc.data();
          docData.id = doc.id;
          resolve(docData);
        });
        resolve("Not Found");
      })
      .catch(function (error) {
        reject(error);
      });
  });
  ExistingUserQuery.then((result) => {
    if (result === "Not Found") {
      alert("Looks like you are not registered yet.. Please register yourself first on https://expo.globalbuyersellermeet.com/");
    } else {
      if (result.isVerified === false) {
        updateAccountStatus(result.id).then(success => {
          console.log('Account status update success', success);
          localStorage.setItem("name", result.name);
          localStorage.setItem("mobileno", result.mobileNumber);
          localStorage.setItem("email", result.email);
          localStorage.setItem("countryCode", result.countryCode);
          localStorage.setItem("registerStatus", true);
          let currentTime = new Date().getTime();
          localStorage.setItem("login time", currentTime);

          sendWelcomeEmailToUser(result.email);
          document.getElementById('expoInfoPopup').style.display = 'block';
        });
      } else {
        alert('Looks like your account is already verified... You will redirected in some time');
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      }
    }
  }).catch((error) => {
    console.error("Already existing user check error", error);
  });
}

function updateAccountStatus(docId) {
  const db = firebase.firestore();
  var userDocRef = db.collection("Users").doc(docId);
  return userDocRef.update({
    isVerified: true
  })
    .then(function () {
      console.log("Document successfully updated!");
    })
    .catch(function (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
}

function CloseRegisterPopup() {
  // document.getElementById("UserRegisterPage").style.display = "none";
  //    document.getElementById("Login-popup").style.display = "none";
  //    document.getElementById("bottommenu").style.display = "block";
  //    document.querySelector("marquee").style.visibility = "visible";
  //    document.getElementById("totalVisitor").style.display = "none";
  //    document.getElementById("regSubmit").disabled = false;
  //    document.getElementById("btn-Login-verify").disabled = false;
  //    // document.getElementById('main-Login-thanksRegpopup').classList.remove("bottomtotop");
  //    // document.getElementById('main-Login-thanksRegpopup').classList.add("revbottomtotop");
  //    document.getElementById("layerOverlay").style.display = "none";
  //    // document.getElementById('Position').style.display = 'none';
  //    document.getElementById("Login-Verify").style.display = "none";
  //    document.getElementById("ThanksLogin").style.display = "none";
  //    document.getElementById("SuccessLoginR").style.display = "none";
  //    document.getElementById("invalidOTP").style.display = "none";
  //    document.getElementById("Login").style.display = "none";
  //    document.getElementById("regSubmit").disabled = false;
  //    document.getElementById("btn-verify").disabled = false;
  //    document
  //      .getElementById("main-thanksRegpopup")
  //      .classList.remove("bottomtotop");
  //    document
  //      .getElementById("main-thanksRegpopup")
  //      .classList.add("revbottomtotop");
  //    document.getElementById("layerOverlay").style.display = "none";
  //    // document.getElementById('Position').style.display = 'none';
  //    document.getElementById("Verify").style.display = "none";
  //    document.getElementById("Thanks").style.display = "none";
  //    document.getElementById("ThanksMob").style.display = "none";
  //    document.getElementById("SuccessR").style.display = "none";
  //    document.getElementById("prefrance-popup").style.display = "none";
  //    document.getElementById("Prefrance").style.display = "none";
  //    document.getElementById("main-Prfranceybox").style.display = "none";
  //    document.getElementById("thanks-Login-reg-popup").style.display = "none";
  //    document.getElementById("layerOverlaypopup").style.display = "none";
}

function closeLoginPopup() {
  if (document.getElementById("UserLoginPage")) {
    document.getElementById("UserLoginPage").style.display = "none";
  }
}
export default IsExistingUserForRegistration;

/**
 * Check for already registered users in Users collection
 * If user found, then it will call a function
 * to setup his cart
 * else it will alert user to register as new user
 * @param {Mobile number given in login page} mobileNumber
 * @param {Country code} CountryCode
 */
export function CheckForLogin(email) {
  return new Promise((resolvelogin, rejectLogin) => {
    const db = firebase.firestore();
    const userCollection = db.collection("Users");
    // let MobileNumber = CountryCode + mobileNumber;
    console.log("email before get is ", email);
    // mobileNumber = parseInt(MobileNumber);
    // console.log(mobileNumber);
    let ExistingUserQuery = new Promise((resolve, reject) => {
      userCollection
        .where("email", "==", email)
        .where("expo", "==", expoName)
        .where("isVerified", "==", true)
        .get()
        .then(function (querySnapshot) {
          if(querySnapshot.size>0){
            querySnapshot.forEach(function (doc) {
              const docData = doc.data();
              localStorage.setItem("name", docData.name);
              
              localStorage.setItem("email", docData.email);
              localStorage.setItem("mobileno", docData.mobileNumber);
              localStorage.setItem("countryCode", docData.countryCode)
              localStorage.setItem("userDocId", doc.id);
              // localStorage.setItem("user-email", docData.email);
              let currentTime = new Date().getTime();
              localStorage.setItem("login time", currentTime);
              sendDataTo3D(
                docData.name,
                docData.countryCode,
                docData.mobileNumber,
                docData.email
              );
              resolve(doc.id);
            });
          }else{
            resolve("Not Found");
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
    ExistingUserQuery.then((result) => {
      if (result === "Not Found") {
        rejectLogin('Not Found')
        alert("You are not registered... Please register first ");
        // localStorage.setItem('registerStatus',false)
        // document.getElementById("login-btn").removeAttribute("disabled");

      } else {
        localStorage.setItem("email", email);
        resolvelogin(email)
        console.log("User found");
        // IncrementVisitorCounter();
        // closeLoginPopup();
        // IsExistingCartFound();
        // CloseRegisterPopup();
      }
    }).catch((error) => {
      console.error("Already existing user check error", error);
    });
  })

}

function updatePreferenceForm(userDetail, callback, product, category, description) {
  let docID = userDetail.id;
  const userCollection = firebase.firestore().collection("Users");
  let myDocToUpdate = userCollection.doc(docID);
  return myDocToUpdate.update({
    callMeBack: callback,
    productIntrested: product,
    userCategory: category,
    description: description,
    isPreferenceFilled: true
  })
    .then(function () {
      console.log("Document successfully updated!", docID);
    })
    .catch(function (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
}

function sendContactDetailsToAC(userDetail) {
  var myDetail = {
    email: userDetail.email,
    name: userDetail.name,
    country: userDetail.countryCode,
    mobileNumber: userDetail.mobileNumber
  };

  // http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  return axios
    .post(
      "https://us-central1-vr-expo.cloudfunctions.net/addNewContact",
      myDetail,
      {
        headers: {
          // 'x-access-token': token
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then(success => {
      console.log("AC Contact send success", success);
      // window.location.href = '/verify';
      // setTimeout(() => {
      // }, 3000);
    }).catch(error => {
      console.error("Error in Sending AC Contact", error);
    })
}

export function isMobile() {
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    } else {
        return false;
    }
}