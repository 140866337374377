// import Registration from "./components/registration/registration";
// import Login from "./components/login/login";
// import Form from './components/mainpage/form'
import "./App.css";
import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/home/home";
import Login from "./components/login/login";
import Verify from "./components/verify/verify";
import Exterior from './components/exterior/exterior'
import Auditorium from "./components/auditorium/auditorium";
// import Login from "./components/login/login";

export class RouterContainer extends Component {
  render() {
    let regStatus = localStorage.getItem("registerStatus");
    console.log("regstatus", regStatus);

    return (
      <div>
        <Router history={createBrowserHistory}>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/exterior" component={Exterior} /> */}
            <Route exact path="/verify" component={Verify} />
            <Route exact path="/auditorium" component={Auditorium} />
            {/* <Route exact path="/verify" component={Verify} /> */}
            {/* <Route exact path="/main" component={Form}/>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Registration} /> */}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default RouterContainer;
