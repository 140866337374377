// import { ContextConsumer } from "../contextProvider";
// import Login from "../login/login";
// import Registration from "../registration/registration";
import React, { Component } from "react";
import logoimg from "../../image/being/logo.png";
import COMPANYNAMEicon from "../../image/being/COMPANY-NAME.png";
import COUNTRYicon from "../../image/being/COUNTRY.png";
import EMAILIDicon from "../../image/being/EMAIL-ID.png";
import MOBILENOicon from "../../image/being/MOBILE-NO.png";
import NAMEicon from "../../image/being/NAME.png";
import $ from "jquery";


import "../login/login.css";
import Footer from '../footer/footer'
import firebase from "firebase";
import {
  IsExistingUserForRegistration,
  CheckForLogin,
} from "../../functions/firebase";
import Bottommenu from "../bottommenu/bottommenu";
let obj = {};
export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReg: true,
      name: "",
      countryCode: "91",
      country: [],
      phone: "",
      companyName: "",
      email: "",
      registerStatus: false,
      ip_address: "",
      cc: [],
    };
  }

  // componentDidMount() {
  //     let regStatus = localStorage.getItem("registerStatus");
  //     if(regStatus===true){this.setState({isReg:true})}
  //     else{this.setState({isReg:false})}
  // }

  toggleForm = () => {
    this.setState({ isReg: !this.state.isReg });
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validatemobile = () => {
    const patternMob = /^[0-9]{8,15}$/;
    const patternIndianMob = /^[6789]{1}[0-9]{9}$/;
    let errmob = patternIndianMob.test(this.state.phone);
    let errormob = patternMob.test(this.state.phone);
    if (this.state.countryCode === "91") {
      console.log(this.state.countryCode);
      if (!errmob) {
        document.getElementById("phone").focus();
        alert("Please enter a valid phone no.");
      } else {
        document.getElementById("companyName").focus();
      }
    } else if (
      this.state.countryCode !== "91" ||
      this.state.countryCode !== "0"
    ) {
      console.log(this.state.countryCode);
      if (!errormob) {
        document.getElementById("phone").focus();
        alert("Please enter a valid phone");
      }
    }
  };

  getCC = () => {
    const docRef = firebase.firestore().collection("CountryCode").doc("Country");
    let countryPromise = new Promise((resolve, reject) => {

      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("Country Code data:", doc.data());
            let myData = doc.data();
            resolve(myData);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            resolve('No Data');
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          reject(error);
        });
    });

    countryPromise.then(result => {
      console.warn("Country List", result);
      let myList = [];
      for(var key in result) {
        let currentObj = {
          countryName: key,
          countryCode: result[key]
        }
        myList.push(currentObj);
      }
      this.sortByKey(myList, 'countryName');
      // console.warn("After Sorting", myList);
      this.setState({
        country: myList
      })
    }).catch(error => {
      console.error("error getting country list", error);
    })
  };

  sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  ValidateEmail = () => {
    //   console.log("Validate ")
    const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let err = patternEmail.test(this.state.email);
    //   console.log(this.state.emailAddress)
    //   console.log(err)

    if (!err) {
      document.getElementById("email").focus();
      alert("Please enter valid Email");
    }
  };

  handleregistration = (event) => {
    let Name = this.state.name;
    let CountryCode = this.state.countryCode;
    let Country = $("#select-country  option:selected").text();
    let MobileNo = this.state.phone;
    let Email = this.state.email;
    let companyName = this.state.companyName;
    let queryString = window.location.search;
    let query_source = new URLSearchParams(queryString);
    let utm_source = query_source.get("utm_source");
    let utm_medium = query_source.get("utm_medium");
    let utm_content = query_source.get("utm_content");
    let utm_campaign = query_source.get("utm_campaign");
    let utm_term = query_source.get("utm_term");
    let utm_initial_referrer = query_source.get("utm_initial_referrer");
    let utm_last_referrer = query_source.get("utm_last_referrer");
    let utm_landing_pagecdeo = query_source.get("utm_landing_pagecdeo");
    let utm_visits = query_source.get("utm_visits");
    let browser_name = navigator.product;
    let browser_version = navigator.appVersion;
    let browser_platform = navigator.platform;
    let ip_address = this.state.ip_address;
    Email = Email.toLowerCase();
    const patternMob = /^\d{8,15}$/;
    const patternIndianMob = /^\d{10}$/;
    const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    console.log("Country Name", Country);
    if (Name === "") {
      alert("Please enter Your Name !");
    } else if (Email === "") {
      alert("Please enter a valid Email-id");
    } else if (CountryCode === "") {
      alert("Please enter countrycode !");
    } else if (MobileNo === "") {
      alert("Please enter valid mobile number");
    } else {
      if (!patternEmail.test(Email)) {
        alert("Please enter a valid Email-id");
      } else if (!patternMob.test(MobileNo)) {
        alert("Please enter valid mobile number");
      } else if (
        (CountryCode === "91" && !patternIndianMob.test(MobileNo)) ||
        (CountryCode === "91" && parseInt(MobileNo[0]) < 6)
      ) {
        console.warn("Not valid indian number", MobileNo);
        alert("Please enter valid 10 digit mobile number");
      } else {
        IsExistingUserForRegistration(
          Name,
          Email,
          Country,
          CountryCode,
          MobileNo,
          companyName,
          utm_source,
          utm_medium,
          utm_content,
          utm_term,
          utm_campaign,
          utm_visits,
          utm_initial_referrer,
          utm_landing_pagecdeo,
          utm_last_referrer,
          browser_name,
          browser_platform,
          browser_version,
          ip_address
        ).then(() => this.props.isLogged());
      }
    }
  };

  componentDidMount() {
    this.get_ip();
    this.getCC();
  }

  get_ip = () => {
    fetch("https://api.ipify.org/?format=json")
      .then((results) => results.json())
      .then((data) => {
        console.log(data.ip);
        this.setState({ ip_address: data.ip.toString() });
      });
  };

  onlyNumberKey = (e) => {
    // Only ASCII charactar in that range allowed
    let ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  };

  checkLogin = () => {
    let email = this.state.email;
    // let CountryCode = this.state.countryCode;
    // console.log(LoginMobile);
    if (email === "") {
      alert("Please enter your registered email");
    } else {
      const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (!patternEmail.test(email)) {
        alert("Please enter valid emailID");
      } else {
        // this.props.setPreferenceFromDb();
        // document.getElementById("login-btn").disabled = "true";
        CheckForLogin(email).then(mail => {
          // this.props.setMail(mail)
          console.log('Login', mail);
          this.props.setPreferenceFromDb(mail);
          this.setState({
            email: "",
            // cc: "91",
          });
          localStorage.setItem("registerStatus", true);
          this.props.isLogged();
        }).catch(error => {
          console.log('Should stop login popup to close', error);
        })
        
      }
    }
  };

  login = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      this.checkLogin();
    }
  };

  register = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      this.handleregistration();
    }
  };
  focusPhone = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("phone").focus();
    }
  };
  focusEmail = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("email").focus();
    }
  };
  focusCompany = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("companyName").focus();
    }
  };
  render() {
    return (
      <div>

        <div id="UserRegisterPage" className="layerform">
          <div className="logoposition">
            <img src={logoimg} className="widthbeinglogo"></img>
          </div>
          {this.state.isReg && (
            <div className="loginformposition toptobottom">
              <div className="formlogin">
                <div className="headerlogin">
                  <h3 className="logintitle">Register</h3>
                </div>
                <div className="formcontainer">
                  <div id="input_container" className="marginbottmflex">
                    <input
                      id="name"
                      autoFocus
                      className="input-field"
                      autoComplete="off"
                      type="text"
                      placeholder="Name*"
                      name="name"
                      value={this.state.name}
                      onKeyUp={this.focusPhone}
                      onChange={this.handleChange}
                      required
                    />
                    <img src={NAMEicon} id="input_img" />
                  </div>

                  <div className="flexinput">
                    <div
                      id="input_container"
                      className="marginbottmflex dropdown_container"
                      style={{ marginRight: "10px" }}
                    >
                      <div className="countrybox">
                        <select
                          id="select-country"
                          name="countryCode"
                          onChange={this.handleChange}
                          value={this.state.countryCode}
                          className="marignlefttwo"
                        >
                          {/* <option value="91">India</option> */}
                          {(this.state.country).map((itemCountry) => {
                            return <option value={itemCountry['countryCode']}>{itemCountry['countryName']}</option>;
                          })}
                        </select>
                      </div>
                      <img src={COUNTRYicon} id="input_img" />
                    </div>

                    <div
                      id="input_container"
                      className="marginbottmflex widthnumberinput "
                    >
                      <input
                        id="phone"
                        // autoFocus
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        // onBlur={this.validatemobile}
                        onKeyPress={this.onlyNumberKey}
                        onKeyUp={this.focusCompany}
                        className="input-field"
                        autoComplete="off"
                        type="number"
                        placeholder="Phone No."
                        required
                      />
                      <img src={MOBILENOicon} id="input_img" />
                    </div>
                  </div>

                  <div id="input_container" className="marginbottmflex">
                    <input
                      id="companyName"
                      className="input-field"
                      autoComplete="off"
                      type="text"
                      placeholder="Company Name"
                      name="companyName"
                      value={this.state.companyName}
                      onChange={this.handleChange}
                      onKeyUp={this.focusEmail}
                      required
                    />
                    <img src={COMPANYNAMEicon} id="input_img" />
                  </div>

                  <div id="input_container" className="marginbottmflex">
                    <input
                      id="email"
                      className="input-field"
                      autoComplete="off"
                      type="email"
                      placeholder="Email id"
                      name="email"
                      value={this.state.email}
                      // onBlur={this.validateEmail}
                      onChange={this.handleChange}
                      onKeyUp={this.register}
                      required
                    />
                    <img src={EMAILIDicon} id="input_img" />
                  </div>

                  <button
                    className="logintbn marginbottmflex"
                    onClick={this.handleregistration}
                  >
                    Register
                </button>
                  <div className="CTA" style={{ marginTop: "10px" }}>
                    {/* <a href="#" className="switch"> */}
                    {/* <Link className="switch" to={"/login"}>
                            I have an account
                          </Link> */}

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="switch" onClick={this.toggleForm}>
                      I've an account
                  </a>
                  </div>
                </div>
              </div>
            </div>

            // <Registration toggleForm={this.toggleForm} isLogged={this.props.isLogged} />
          )}
          {!this.state.isReg && (
            <div className="loginformposition toptobottom">
              <div className="formlogin">
                <div className="headerlogin">
                  <h3 className="logintitle">Login</h3>
                </div>
                <div className="formcontainer">
                  <div id="input_container" className="marginbottmflex">
                    <input
                      id="email"
                      className="input-field"
                      autoComplete="off"
                      type="email"
                      placeholder="Email id"
                      name="email"
                      value={this.state.email}
                      // onBlur={this.validateEmail}
                      onChange={this.handleChange}
                      onKeyUp={this.login}
                      required
                    />
                    <img src={EMAILIDicon} id="input_img" />
                  </div>
                  <button
                    className="logintbn marginbottmflex"
                    onClick={this.checkLogin}
                  >
                    Login
                </button>
                  <div className="CTA" style={{ marginTop: "10px" }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="switch" onClick={this.toggleForm}>
                      I'm new
                  </a>
                    {/* <Link className="switch" to={"/register"}>
                            I'm new
                          </Link> */}
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
            // <Login toggleForm={this.toggleForm} isLogged={this.props.isLogged} />
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Form;
