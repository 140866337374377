import React, { Component } from "react";
import "./login.css";
import $ from "jquery";
import { IsExistingUserForRegistration } from "../../functions/firebase";
import logoimg from "../../image/being/logo.png";
import COMPANYNAMEicon from "../../image/being/COMPANY-NAME.png";
import COUNTRYicon from "../../image/being/COUNTRY.png";
import EMAILIDicon from "../../image/being/EMAIL-ID.png";
import MOBILENOicon from "../../image/being/MOBILE-NO.png";
import NAMEicon from "../../image/being/NAME.png";
import Footer from "../footer/footer";
import Bottommenu from "../bottommenu/bottommenu";

// import Registration from "../registration/registration";
// import { Router } from 'react-router';
// import { Link } from "react-router-dom";
export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      countryCode: "",
      companyName: "",
      isLogged: false,
    };
  }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validatemobile = () => {
    const patternMob = /^[0-9]{8,15}$/;
    const patternIndianMob = /^[6789]{1}[0-9]{9}$/;
    let errmob = patternIndianMob.test(this.state.phone);
    let errormob = patternMob.test(this.state.phone);
    if (this.state.countryCode === "91") {
      console.log(this.state.countryCode);
      if (!errmob) {
        document.getElementById("phone").focus();
        alert("Please enter a valid phone no.");
      } else {
        document.getElementById("companyName").focus();
      }
    } else if (
      this.state.countryCode !== "91" ||
      this.state.countryCode !== "0"
    ) {
      console.log(this.state.countryCode);
      if (!errormob) {
        document.getElementById("phone").focus();
        alert("Please enter a valid phone");
      }
    }
  };

  ValidateEmail = () => {
    //   console.log("Validate ")
    const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let err = patternEmail.test(this.state.email);
    //   console.log(this.state.emailAddress)
    //   console.log(err)

    if (!err) {
      document.getElementById("email").focus();
      alert("Please enter valid Email");
    }
  };

  handleregistration = (event) => {
    let Name = this.state.name;
    let CountryCode = this.state.countryCode;
    let MobileNo = this.state.phone;
    let Email = this.state.email;
    let companyName = this.state.companyName;
    let queryString = window.location.search;
    let query_source = new URLSearchParams(queryString);
    let utm_source = query_source.get("utm_source");
    let utm_medium = query_source.get("utm_medium");
    let utm_content = query_source.get("utm_content");
    let utm_campaign = query_source.get("utm_campaign");
    let utm_term = query_source.get("utm_term");
    let utm_initial_referrer = query_source.get("utm_initial_referrer");
    let utm_last_referrer = query_source.get("utm_last_referrer");
    let utm_landing_pagecdeo = query_source.get("utm_landing_pagecdeo");
    let utm_visits = query_source.get("utm_visits");
    let browser_name = navigator.product;
    let browser_version = navigator.appVersion;
    let browser_platform = navigator.platform;
    let ip_address = this.state.ip_address;
    Email = Email.toLowerCase();
    const patternMob = /^\d{8,15}$/;
    const patternIndianMob = /^\d{10}$/;
    const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (Name === "") {
      alert("Please enter Your Name !");
    } else if (Email === "") {
      alert("Please enter a valid Email-id");
    } else if (CountryCode === "") {
      alert("Please enter countrycode !");
    } else if (MobileNo === "") {
      alert("Please enter valid mobile number");
    } else {
      if (!patternEmail.test(Email)) {
        alert("Please enter a valid Email-id");
      } else if (!patternMob.test(MobileNo)) {
        alert("Please enter valid mobile number");
      } else if (
        (CountryCode === "91" && !patternIndianMob.test(MobileNo)) ||
        (CountryCode === "91" && parseInt(MobileNo[0]) < 6)
      ) {
        console.warn("Not valid indian number", MobileNo);
        alert("Please enter valid 10 digit mobile number");
      } else {
        IsExistingUserForRegistration(
          Name,
          Email,
          CountryCode,
          MobileNo,
          companyName,
          utm_source,
          utm_medium,
          utm_content,
          utm_term,
          utm_campaign,
          utm_visits,
          utm_initial_referrer,
          utm_landing_pagecdeo,
          utm_last_referrer,
          browser_name,
          browser_platform,
          browser_version,
          ip_address
        );
      }
    }
  };
  componentDidMount() {
    this.get_ip();
  }
  get_ip = () => {
    const access_key = "820cb517c413fb1d75ae5a4ffe36dbe2";
    let myipPromise = new Promise((resolve, reject) => {
      fetch("https://api.ipify.org/?format=json")
        .then((results) => results.json())
        .then((data) => {
          console.log(data.ip);
          this.setState({ ip_address: data.ip.toString() });
          resolve();
        });
    });

    console.log("ip_address", this.state.ip_address);
    myipPromise.then(() => {
      $.ajax({
        url:
          "http://api.ipstack.com/" +
          this.state.ip_address +
          "?access_key=" +
          access_key,
        dataType: "jsonp",
        success: function (json) {
          // output the "capital" object inside "location"
          // alert(json.location.capital);
          console.log(json);
        },
      });
    });
  };

  onlyNumberKey = (e) => {
    // Only ASCII charactar in that range allowed
    let ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  };

  render() {
    const { isLogged } = this.state;
    return (
      <div>
        {/* {isLogged} */}
        <div className="loginformposition toptobottom">
          <div className="formlogin">
            <div className="headerlogin">
              <h3 className="logintitle">Register</h3>
            </div>
            <div className="formcontainer">
              <div id="input_container" className="marginbottmflex">
                <input
                  id="name"
                  autoFocus
                  className="input-field"
                  autocomplete="off"
                  type="text"
                  placeholder="Name*"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
                <img src={NAMEicon} id="input_img" />
              </div>
              <div className="flexinput">
                <div
                  id="input_container"
                  className="marginbottmflex dropdown_container"
                  style={{ marginRight: "10px" }}
                >
                  <div className="countrybox">
                    <select
                      id="select-country"
                      name="countryCode"
                      onChange={this.handleChange}
                      value={this.state.countryCode}
                      className="marignlefttwo"
                    >
                      <option value="91">India</option>

                      <option value="93">Afghanistan(+93)</option>
                      <option value="355">Albania(+355)</option>
                      <option value="213">Algeria(+213)</option>
                    </select>
                  </div>
                  <img src={COUNTRYicon} id="input_img" />
                </div>

                <div
                  id="input_container"
                  className="marginbottmflex widthnumberinput"
                >
                  <input
                    id="phone"
                    // autoFocus
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    onBlur={this.validatemobile}
                    onKeyPress={this.onlyNumberKey}
                    className="input-field"
                    autocomplete="off"
                    type="number"
                    placeholder="Phone No."
                    name="phone"
                    required
                  />
                  <img src={MOBILENOicon} id="input_img" />
                </div>
              </div>

              <div id="input_container" className="marginbottmflex">
                <input
                  id="companyName"
                  className="input-field"
                  autocomplete="off"
                  type="text"
                  placeholder="Company Name"
                  name="companyName"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  required
                />
                <img src={COMPANYNAMEicon} id="input_img" />
              </div>

              <div id="input_container" className="marginbottmflex">
                <input
                  id="email"
                  className="input-field"
                  autocomplete="off"
                  type="text"
                  placeholder="Email id"
                  name="email"
                  value={this.state.email}
                  onBlur={this.validateEmail}
                  onChange={this.handleChange}
                  required
                />
                <img src={EMAILIDicon} id="input_img" />
              </div>

              <button
                className="logintbn marginbottmflex"
                onClick={this.handleregistration}
              >
                Register
              </button>
              <p>
                Already Have an account?<a>Login</a>
              </p>
            </div>
          </div>
        </div>

        <div className="loginformposition toptobottom">
          <div className="formlogin">
            <div className="headerlogin">
              <h3 className="logintitle">Register</h3>
            </div>
            <div className="formcontainer">
              <div id="input_container" className="marginbottmflex">
                <input
                  id="email"
                  className="input-field"
                  autocomplete="off"
                  type="text"
                  placeholder="Email id"
                  name="email"
                  value={this.state.email}
                  onBlur={this.validateEmail}
                  onChange={this.handleChange}
                  required
                />
                {/* <img src={passwordicon} id="input_img" /> */}
              </div>
              <button
                className="logintbn marginbottmflex"
                onClick={this.handleregistration}
              >
                Login
              </button>
            </div>
          </div>
        </div>

        <Footer />
        {/* <div className="footer" id="Footer">
          <footer className="footer_area">
            <div className="container">
              <p className="footer-text">
                Powered by{" "}
                <a
                  href="https://melzo.com/"
                  target="_blank"
                  className="linkcolor"
                >
                  melzo.com
                </a>{" "}
              </p>
            </div>
          </footer>
        </div> */}
      </div>
    );
  }
}

export default Login;
