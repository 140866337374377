import React, { Component } from "react";
// import './footer.css'
export class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer" id="Footer">
          <footer className="footer_area">
            <div className="container">
              <p className="footer-text">
                Powered by{" "}
                <a
                  href="https://melzo.com/"
                  target="_blank"
                  className="linkcolor"
                >
                  melzo.com
                </a>{" "}
                and <a
                  href="https://beingexporter.com/"
                  target="_blank"
                  className="linkcolor"
                >Being Exporter</a>
              </p>
            </div>
          </footer>
        </div>

      </div>
    );
  }
}

export default Footer;
