// import { ContextProvider } from "../contextProvider";
import React, { Component } from "react";
import $ from 'jquery';
import Footer from "../footer/footer";
import BackGround from "../background/background";
import Form from "../mainpage/form";
import PrefrenceForm from "../bottommenu/PrefrenceForm";
import firebase from 'firebase'
import Bottommenu from "../bottommenu/bottommenu";
import { isMobile, sendDataTo3D } from "../../functions/firebase";
import Exterior from "../exterior/exterior";
import logoimg from "../../image/being/logo.png";
import Auditorium from "../auditorium/auditorium";

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrefrenceFilled: true,
      isUserExist: false,
      email: '',
      isExterior: true,
      isAuditorium: false,
      isChatPanelVisible: false,
      chatProjectId: ''
    };
  }

  //   checkForProjectId = (e) => {
  //     if (e.data.isProjectIdExist) {
  //       if (e.data.projectId) {
  //       console.log('project id throght event',e.data.projectId)
  //       let projectid = e.data.projectId
  //       firebaseApp.firestore().collection('StallList').where('projectid','==',projectid).get().then(snapshot=>{
  //         if(snapshot.size>0){
  //           console.log('doc found')
  //           this.setState({
  //             isChatPanelVisible:true,
  //             chatProjectId:projectid
  //           })
  //         }
  //         else{
  //           this.setState({
  //             isChatPanelVisible:false,
  //             chatProjectId:''
  //           })
  //         }
  //       })

  //     }
  //   }
  // }
  componentDidMount() {
    this.setPreferenceFromDb1()
    window.addEventListener('load', this.checkForHashProject);
    window.addEventListener('message', this.projectClick);
    // window.addEventListener('message',this.jitsimeet)
    let regStatus = localStorage.getItem("registerStatus");
    if (regStatus !== null) {
      this.setState({ isUserExist: true });
      // console.log("register", this.state.isUserExist);
    } else {
      this.setState({ isUserExist: false });
      let name = localStorage.getItem('name');
      let mobileNum = localStorage.getItem('mobileno');
      let email = localStorage.getItem('email');
      let countryCode = localStorage.getItem('countryCode');
      sendDataTo3D(name, countryCode, mobileNum, email);
    }

    // window.addEventListener('message',this.checkForProjectId,false)

  }





  componentWillUnmount() {
    //6006d9848c5ae500111e8722
    window.removeEventListener('load', this.checkForHashProject);
    // window.removeEventListener('message', this.checkForProjectId);
  }



  projectClick = (e) => {
    if (e.origin !== 'https://uppercrustbypinclick.com/' && e.origin !== 'http://localhost:8080') {

      if (e.data.isImageClicked) {

        if (e.data.mslKey === 'expo.virtualpropexpo2020.com/auditorium') {
          this.setState({ isExterior: false })
          this.setState({ isAuditorium: true })
        }
      }

      if (e.data.isLinkClicked) {
        if (e.data.url) {
          console.log('data.url',e.data.url)
          if (e.data.url.includes('meet.google')) {
            let top = (window.innerHeight - 400) / 2;
            let left = (window.innerWidth - 600) / 2;

            window.open(e.data.url, 'new name', 'width=600,height=400,top=' + top + ', left=' + left + '')
          }
          else if (e.data.url === "melzounite") {
            let top = (window.innerHeight - 400) / 2;
            let left = (window.innerWidth - 600) / 2;
            console.log('melzounite_room',e.data.room)
            localStorage.setItem('jitsi-roomname',e.data.room)
            window.open("melzounite.html", 'frame'+e.data.room, 'width=600,height=400,top=' + top + ', left=' + left + '')
            
          }
          else if (e.data.target === "_blank") {
            
            $(`<form action=${e.data.url} target="_blank"></form>`).appendTo('#root').submit();
          } else {
            if (e.data.url.endsWith('.pdf')) {
              const form = document.createElement("form");
              form.setAttribute('method', "get");
              form.setAttribute('target', e.data.target);
              form.setAttribute('action', e.data.url);
              document.body.append(form);
              form.submit()
            }
            else {

              window.location.href = e.data.url;
            }


          }
        }
      }
    }
  }

  jitsimeet = (e) => {

  }
  checkForHashProject = () => {
    if (window.location.hash.replace('#', '') !== '') {
      let projectToChange = window.location.hash.replace('#', '');
      console.log('Project ID', projectToChange);
      if (document.getElementById('projectBackground')) {
        let projectLink = 'https://vr-expo.firebaseapp.com/dekho/' + projectToChange + '/0/novr/none'
        document.getElementById('projectBackground').setAttribute('src', projectLink);
      }
    }
  }

  setMail = (email) => {
    this.setState({
      email: email
    })
  }
  setPreferenceFromDb = (mail) => {
    // User is signed in.
    firebase.firestore().collection('Users').where('expo', '==', 'being-exporter').where('email', '==', mail ? mail : '').get().then(snapshot => {
      snapshot.forEach(doc => {
        this.setState({
          isPrefrenceFilled: doc.data().isPreferenceFilled,
        })
      })
    })

  }

  setPreferenceFromDb1 = () => {
    // User is signed in.
    firebase.firestore().collection('Users').where('expo', '==', 'being-exporter').where('email', '==', localStorage.getItem('email')).get().then(snapshot => {
      snapshot.forEach(doc => {
        this.setState({
          isPrefrenceFilled: doc.data().isPreferenceFilled,
        })
      })
    })

  }

  isLogged = () => {
    this.setState({ isUserExist: true }, () => {
      this.setPreferenceFromDb()
    });
  };

  isPrefrence = () => {
    this.setState({ isPrefrenceFilled: true }, () => {
      console.log("User", this.state.isUserExist);
    });
  };

  changeValueForState = (exteriorValue, auditoriumValue) => {
    console.warn('exterior value is ', exteriorValue)
    console.warn('auditorium value is ', auditoriumValue)
    this.setState({ isAuditorium: auditoriumValue })
    this.setState({ isExterior: exteriorValue })
  }

  render() {
    return (
      <div>

        <div className="logoposition">
          <img src={logoimg} alt="logo" className="widthbeinglogo" />
        </div>
        {this.state.isExterior &&
          <div>
            <BackGround iframePath="https://vr-expo.firebaseapp.com/dekho/5ffd314d0ea14a0011e33505/0/novr/none" />
            {this.state.isUserExist && !this.state.isPrefrenceFilled && (
              <PrefrenceForm
                setPreferenceFromDb1={this.setPreferenceFromDb1}
                isPrefrence={() => {
                  this.isPrefrence();
                }}
              />
            )}
            {/* {this.state.isPrefrenceFilled && this.state.isUserExist &&
              <React.Fragment>

                <Exterior />

              </React.Fragment>
            } */}
            {/* // <ContextProvider value={this.isLogged}> */}
            {!this.state.isUserExist && <Form isLogged={this.isLogged} setPreferenceFromDb={(mail) => { this.setPreferenceFromDb(mail) }} setMail={() => { this.setMail() }} />}
            {/* </ContextProvider> */}
            {/* // <Exterior/> */}
            <Footer />
          </div>
        }
        {this.state.isAuditorium && <Auditorium changeValueForState={this.changeValueForState} />}
        {this.state.isPrefrenceFilled && this.state.isUserExist &&
          <Bottommenu changeValueForState={this.changeValueForState} />
        
        }
      </div>
    );
  }
}

export default Home;