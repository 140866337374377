/* eslint-disable  */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint react/prop-types: 0 */
/* eslint-disable no-param-reassign */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import LanguageIcon from "@material-ui/icons/Language";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Alert from '@material-ui/lab/Alert';
import "./filter.css";
import FilterData from '../FilterPopup/filterData.js'
import selectedProjectInfo from '../chatpanel/selectedProjectObj'
import CategoryData from '../../../src/data'
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
});
const styless = {
  dialogPaper: {
    width: "80vh",
    maxHeight: "80vh"
  }
};
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
let dataArray = [];
let enteredPassword;
// let selectedProjectId = '';

let duplicateFilterData = FilterData
class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "",
      categoryList: [],
      exhibitorList: [],
      tempExhibitorList: [],
      stallbannererror: false,
      enteredPassword: "",
      displayEmailSuccessNotificaton: false,
      catagoryData:[],
      catagoryvalue:''
    };
  }

  componentDidMount() {
    const self = this;
    const exhibitors = [];
    const categories = [];
    // dataArray.push(FilterData);
    // this.setState({ exhibitorList: dataArray,tempExhibitorList: dataArray });


    this.setState({
      exhibitorList: FilterData,
      catagoryData:CategoryData
    })

    

  }

  handleChange = event => {
    this.setState({
      selectedCategory: event.target.value
    });
  };

  handleFilterClick = () => {
    const value = this.state.selectedCategory;

    for (let i = 0; i < this.state.tempExhibitorList.length; i++) {
      if (!this.state.tempExhibitorList[i].productCategory) {
        this.state.tempExhibitorList[i].productCategory = [];
      }
    }
    const tempdata = this.state.tempExhibitorList.filter(
      exhibitor =>
        this.state.selectedCategory
          ? exhibitor.productCategory.includes(this.state.selectedCategory)
          : true
    );

    this.setState({
      exhibitorList: tempdata
    });
  };

  setSearch = e => {
    const tempData = duplicateFilterData.filter(exhibitor =>
      exhibitor.companyName.toLowerCase().includes(e.target.value.toLowerCase()) ||
      exhibitor.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      exhibitorList: tempData,
    
    });
  };

  setSearchCategory = e => {
    if(e.target.value=="All"){

      this.setState({
        exhibitorList: FilterData
      });
    }else{
      console.log("inside setsearch",e.target.value)
      const tempData = duplicateFilterData.filter(exhibitor =>
        exhibitor.category.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        exhibitorList: tempData,
      
      });

    }
  };

  onClose = () => {

  }

  setSearchCity = e => {
    console.log('called')

    const tempData = this.state.tempExhibitorList.filter(exhibitor =>
      exhibitor.city ? exhibitor.city.toLowerCase().includes(e.target.value.toLowerCase()) : exhibitor.companyName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      exhibitorList: tempData
    });
  };

  setSearchState = e => {
    console.log('called')

    const tempData = this.state.tempExhibitorList.filter(exhibitor =>
      exhibitor.state ? exhibitor.state.toLowerCase().includes(e.target.value.toLowerCase()) : exhibitor.companyName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      exhibitorList: tempData
    });
  };

  openExhibitorStall(projectId) {
    
  this.props.onClose();
  

  if(selectedProjectInfo.projectid == projectId){
   
  }else{
  
    const iframeEl = document.getElementById("projectBackground");
    
    const projectInfo = {
      isExpoHomeClicked: true,
      projectId: projectId,
      sceneIndex: "0",
    };
  
      console.log('selected project id ---- ',projectId)
      // window.selectedProjectId = projectId
    // if(window.projectid !== projectId){
  
    
      iframeEl.contentWindow.postMessage(projectInfo, "*");
      this.props.changeProjectId(projectId)
    // }
    selectedProjectInfo.projectid=projectId
  }

  }
  requestExhibitorStallPassword = (exhibitorEmail, visitorEmail) => {
    let mailObj = {
      visitorEmail: visitorEmail,
      exhibitorEmail: exhibitorEmail,
      subject1: `Stall Password Request`,
      text1: `Do you want to share your password with ${visitorEmail}`,
      html1: `Do you want to share your password with ${visitorEmail}`
    };


    getUserToken().then(token => {
      if (!TrackVisitor.isTester) {
        contactExhiBitor({ token: token }, mailObj)
          .then(res => {
            console.log(res)
            this.setState({
              displayEmailSuccessNotificaton: true
            })
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  }

  stallPasswordSubmitted = (projectId, exhibitorEmail) => {
    // const verifyStallPassword = new Promise((resolve,reject)=>{
    //   firebase.firestore().collection('')
    // })
    console.log('Exhibitor Mail', exhibitorEmail)
    const exhibitorQuery = new Promise((resolve, reject) => {
      const exhibitorData = firebase
        .firestore()
        .collection("users")
        .where("userRole", "==", "exhibitor")
        .where("projectId", "==", projectId)
      exhibitorData
        .get()
        .then(querySnapshot => {
          dataArray = [];
          querySnapshot.forEach(doc => {
            const docData = doc.data();
            dataArray = docData
          });
          resolve(dataArray.stallPassword);
        })
        .catch(error => {
          reject(error);
        });
    });
    exhibitorQuery.then(result => {
      console.log(result)
      if (this.state.enteredPassword == result) {
        const isStallVisited = new Promise((resolve, reject) => {
          document.getElementById("scene").emit("switch-project", {
            link: projectId
          })
          resolve('success')
        })
        isStallVisited.then(res => {

          let mailObj = {
            visitorEmail: firebase.auth().currentUser.email,
            exhibitorEmail: exhibitorEmail,
            subject1: `AEPC | Your stall has just been visited!`,
            text1: `Your stall has just been visited by ${firebase.auth().currentUser.email} at ${new Date()}.`,
            html1: `Your stall has just been visited by ${firebase.auth().currentUser.email} at ${new Date()}.`
          };

          getUserToken().then(token => {
            if (!TrackVisitor.isTester) {
              contactExhiBitor({ token: token }, mailObj)
                .then(res => {
                  console.log(res)

                })
                .catch(err => {
                  console.log(err);
                });
            }
          });
        })

      } else {


      }
    }).catch(err => {
    });


    this.setState({

    })
  }

  handleStallPasswordChange = e => {
    this.setState({
      enteredPassword: e.target.value
    });
  };
  handleChange = (event) => {

    this.setState({
      [event.target.name]:event.target.value
    })
    
  };
  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={this.props.onClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.onClose}
          >
            <div className="row">
              <div className="col-12">Exhibitors</div>
            </div>
          </DialogTitle>

          <DialogContent dividers className="productdialogheight">
            <Paper elevation={2} className="exhibitorTopBar">
              <div >
                <Box className="flexbutton" style={{display:"flex" }}>

                  <FormControl style={{width:"auto"}} >

                    <TextField
                      label="Search By Company & Description"
                      className="marginrightleftbtn"
                     
                      onChange={this.setSearch}
                      variant="outlined"

                    />
                  </FormControl>
           
                <FormControl className="marginrightleftbtn" variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">Categories</InputLabel>

                  {/* <InputLabel id="demo-simple-select-outlined-label" style={{padding:"5px"}}> Categories</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // variant="outlined"
                      // open={open}
                      // onClose={this.handleClose}
                      // onOpen={this.handleOpen}
                      // value={}
                      name="catagoryvalue"
                      
                      onChange={this.setSearchCategory}
                    >
                        { this.state.catagoryData.map((value)=>{
      return <MenuItem key={value} value={value}>{value}</MenuItem>
    })}
                    </Select>
              </FormControl>
              <Button color="primary" variant="contained" onClick={()=>{ this.setState({
        exhibitorList: FilterData
      });}} style={{width:"10%"}}>
                Reset
                </Button>
                </Box>

              </div>
            </Paper>

            <div className="row">
              {this.state.exhibitorList.map(exhibitor => (
                <React.Fragment>

                  <div
                    className="col-12 col-md-3"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <div id="filter-card">
                      <div
                        className="card-image waves-effect waves-block waves-light"
                        onClick={() =>
                          this.openExhibitorStall(exhibitor.projectid)
                        }
                      >
                        <div>
                          <img
                            name="stallbannererror"
                            onError={() => { }}
                            src={
                              !(exhibitor.link === "")
                                ? exhibitor.link
                                : fallbackimage
                            }
                            alt=""
                          />
                        </div>

                      </div>
                      <div className="card-content">

                        <span className="card-title activator grey-text text-darken-4">
                          {exhibitor.companyName}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  ) : (
                     ""
                   )} */}
                </React.Fragment>
              ))}
            </div>
          </DialogContent>

        </Dialog>

      </div>
    );
  }
}

export default Filter;
