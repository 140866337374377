import React, { Component } from 'react'
// import { analyticsFunction, updateEndTime } from '../functions/helpers/analytics-function'
import playImg from './medexIcon/play-med.png';
import pauseImg from './medexIcon/pause-med.png';
import './lobby-page.css';
const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/being-exporter-inauguration.mp4'
const exteriorIframeSrc = 'https://angles-expo.firebaseapp.com/dekho/5fe70a89bb962b001161c7b5/0/novr/none'
const expoName = 'being-exporter';
const roomName = 'exterior'
const sceneName = 'exterior'

export default class Exterior extends Component {

    componentDidMount() {
        
        // var video = document.getElementById('2dVideo');
        // video.addEventListener('timeupdate', this.updateCountdown);
        localStorage.setItem('Location', 'Exterior')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('exteriorIframe');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name')+"-"+localStorage.getItem('email'),
                    mobileno: localStorage.getItem('countryCode')+localStorage.getItem('mobileno'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
                
            }
        }, 4000);
        // analyticsFunction(roomName)

        
        
    }

    updateCountdown = () => {

        var video = document.getElementById('2dVideo');
        var timeSpan = document.querySelector('#countdown span');
        let hours;
        let totalSeconds = video.duration - video.currentTime;
        let minutes;
        let seconds;
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        minutes = Math.floor(totalSeconds / 60);
        seconds = totalSeconds % 60;
        // let time = new Date(video.duration - video.currentTime * 1000).toISOString().substr(11, 8);
        timeSpan.innerText = minutes.toString().padStart(2, '0') + ':' + seconds.toFixed().toString().padStart(2, '0');
        console.warn(hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toFixed().toString().padStart(2, '0'))
    }
    

    playVideo = () => {
        if (document.getElementById('2dVideo') &&
            document.getElementById('Pause2dVideo') &&
            document.getElementById('Play2dVideo') &&
            document.getElementById('startExperienceButton')) {
            document.getElementById('2dVideo').play();
            document.getElementById('2dVideo').volume = 1;
            document.getElementById('Pause2dVideo').style.display = 'block';
            document.getElementById('Play2dVideo').style.display = 'none';
            document.getElementById('Play2dVideoTxt').innerHTML = "Pause Video"
            // document.getElementById('countdown').style.display = 'block';
            setTimeout(() => {
                document.getElementById('startExperienceButton').style.display = 'block';
            }, 55000);
        }
    }

    pauseVideo = () => {
        if (document.getElementById('2dVideo') &&
            document.getElementById('Pause2dVideo') &&
            document.getElementById('Play2dVideo')) {
            document.getElementById('2dVideo').pause();

            document.getElementById('2dVideo').volume = 0;
            document.getElementById('Play2dVideo').style.display = 'block'
            document.getElementById('Pause2dVideo').style.display = 'none'

            document.getElementById('Play2dVideoTxt').innerHTML = "Play Video"
            // document.getElementById('countdown').style.display = 'none';
        }
    }


    addNewEntry = () => {
        if (localStorage.getItem('entryAdded') !== 'false') {
            let Name;
            if (localStorage.getItem('name')) {
                Name = localStorage.getItem('name')
            }
            else {
                Name = ''
            }
            let email;
            if (localStorage.getItem('email')) {
                email = localStorage.getItem('email')
            }
            else {
                email = ''
            }
            let uid;
            if (localStorage.getItem('uid')) {
                uid = localStorage.getItem('uid')
            }
            else {
                uid = ''
            }
            // const db = dbFirebaseApp.firestore();
            // db.collection("MetaILDLiveUser").add({
            //     name: Name,
            //     sceneName: sceneName,
            //     uid: uid,
            //     isRaisedHand: 'false',
            //     email: email,
            //     startTime: new Date().getTime(),
            //     expo: 'medex'
            // })
            //     .then(function (docRef) {
            //         console.log("Document written with ID: ", docRef.id);
            //         console.warn('entry added')
            //         localStorage.setItem('docRef', docRef.id)
            //         localStorage.setItem('entryAdded', false)
            //     })
            //     .catch(function (error) {
            //         console.error("Error adding document: ", error);
            //     });
        }
    }

    projectButtonClick = (e) => {
        console.log('exterior click', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            }
        }
      
    }

    startExperiance = () => {
        localStorage.setItem('videoPlayed', true);
        document.getElementById('VideoOverlay').style.display = 'none'

        document.getElementById('video-popup').style.display = 'none'
        document.getElementById('2dVideo').setAttribute('src', '');
    }

    render() {
        return (
            <div>
                {!localStorage.getItem('videoPlayed') && 
                <>
                <div className="overlayVideo" id="VideoOverlay">
                    <div id="countdown" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div>

                </div>
                <div id="video-popup">
                    <video id="2dVideo" className="videopopup" playsInline crossOrigin="anonymous" src={entranceVideo}  preload="auto" ></video>
                    {/* <button  style={{ outline: 'none' }}> */}
                        <img src={playImg} alt="play" id="Play2dVideo" onClick={this.playVideo} className="playbtnwidth"></img>
                    {/* </button> */}
                    {/* <button > */}
                        <img src={pauseImg} id="Pause2dVideo" style={{ display: 'none', outline: 'none' }} onClick={this.pauseVideo}  alt="play" className="playbtnwidth"></img>
                        <p id="Play2dVideoTxt" className="playvideotext">Play Video</p>

                    {/* </button> */}
                    <button id="startExperienceButton" style={{ display: 'none' }} className="btn-glow btn-hover-shine" onClick={this.startExperiance} >Enter Event</button>


                </div>
                </>
                }
                {/* <iframe id="exteriorIframe" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={exteriorIframeSrc}>
                </iframe> */}

            </div>
        )
    }
}
