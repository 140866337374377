import React from "react";
import "./loading-page.css";
import logo from '../../image/being/logo.png'
// import Header from "../header/header";
// import { PAGETYPE } from "../../functions/helpers/constants";
// import { commonFunction } from "../../functions/helpers/analytics-function";
// import Header from "../../helpers/header/header";
let i = 0;
class LoadingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonShow: false
    };
  }
  
  componentDidMount() {
    i = 0;
    setTimeout(() => {
      this.moveProgressBar()
    }, 1000);
    // setTimeout(() => {
      this.setState({isButtonShow:this.props.btnStatus})
    // }, 5000);
  }

  moveProgressBar = () => {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      if (elem) {
        var width = 1;
        var id = setInterval(frame, 15);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            i = 0;
          } else {
            width++;
            elem.style.width = width + "%";
          }
        }
      }
    }
  }

  clickTOPlay=()=>{
    this.props.updateStateForLoader(false)
 }

  render() {
    
    return (
      <div>
        <div id="loader-wrapper">
          {/* <Header /> */}
          <div className="loader-position">
            <img src={logo} alt="" />
            <div id="myProgress">
              <div id="myBar"></div>
            </div>
            {!this.props.btnStatus && <p style={{ marginTop: '10px', fontSize: 14, textAlign: 'center' }} class="loadingtext">Loading Experience... Please wait for a while...</p>}
            {this.props.btnStatus && <p style={{ marginTop: '10px', fontSize: 14, textAlign: 'center' }} class="loadingtext">Click on "Start Webinar" to enter in webinar hall</p>}
           {this.props.btnStatus && <button onClick={this.clickTOPlay} className="clickbutton">Start Webinar</button>}
          </div>

          {/* <span></span> */}

          {/* <div className="loaderbuttonposition" style={{ display: "none" }}> */}
          {/* <div className="" > */}
          {/* <button className="loaderbutton" style={{ border: 0, outline: 0 }}>
            Click{" "}
          </button> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="positioninternettext marginbottominternettext">
          For Slow Internet Speed &nbsp;
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.loadLiteLobby()}
          >
            <b>
              <u>Click Here</u>
            </b>
          </div>
          
        </div> */}
          {/* <div className="positioninternettext">
         to open lite version
        </div> */}
        </div>
      </div>
    );
  }
}
export default LoadingPage;
